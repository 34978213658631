import { Filter } from '@/models/filter';

import { LABEL_ABBREVIATIONS } from '@/utilities/label/label-abbreviation-constants';
import AssetSorting from '@/utilities/asset/asset-sorting';

export function abbreviatedLabelFor(filter: Filter) {
  class InputKey {
    property: string;
    value: string;

    constructor(property: string, value: string) {
      this.property = property;
      this.value = value.toString().toLowerCase();
    }
  }

  let input : InputKey[] = [];

  let filterProperties = Object.keys(filter).sort((a, b) =>  AssetSorting.GetSortOrder(a) - AssetSorting.GetSortOrder(b));
  filterProperties.forEach(property => {
    if (property !== 'layer_type') {
      if (property === 'qualifier') {
        //for qualifiers, take each one separately and create an abbreviation for it
        filter[property].split(' · ').forEach(word => input.push(new InputKey(property, word)));
      } else{
        //for other properties, abbreviate the label as a whole
        input.push(new InputKey(property, filter[property]));
      }
    }
  });

  var abbreviationLabels : string[] = [];
  input.forEach(input_key => {
    let abbreviation: string;
    let special_case_time_filter_regex = '^([01]?[0-9]|2[0-3]):[0-5][0-9]-([01]?[0-9]|2[0-3]):[0-5][0-9]$';  //matches a time range like hh:mm-hh:mm (assumes hh is 24 hour time)
    //first check if the property name as a whole is abbreviated (e.g. Thermal Comfort = TC) and if not, then try each word separately (e.g. Thermal & Comfort)
    
    if (input_key.property == 'single_date') {
      //spcial case for the 'single_date' property in solar daylight.  Shorted the date and display it as the abbreviation
      abbreviation = input_key.value.replaceAll('-', '');
    } else if (input_key.property == 'time_filter' && input_key.value.match(special_case_time_filter_regex) != null) {
      //special case to handle time_range filters formatted as hh:mm-hh:mm
      abbreviation = input_key.value;
      //abbreviate as hh-hh
      // let times = input_key.value.split('-');
      // for (let time of times) {
      //   let hour = time.split(':')[0];
      //   abbreviation += `${hour}-`;
      // }
      // abbreviation = abbreviation.slice(0, -1);

    } else if(input_key.property == 'return_period'){
      abbreviation = input_key.value; //don't abbreviate return period number of years, it gets akward when abbreviated and it's short enough
    } else {
      //regular process
      let indexOfColon = input_key.value.indexOf(':');
      if(indexOfColon !== -1) input_key.value = input_key.value.slice(0, indexOfColon); // remove everything after and including ':'
      abbreviation = LABEL_ABBREVIATIONS[input_key.value]; //get the abbreviation from the constant for the whole key
      if (!abbreviation) { //if it doesn't exist, break into multiple parts and look at each
        let abbreviationsForWord : string[] = [];
        const words = input_key.value.split(' ');  //split string into substrings by a space
        words.forEach(word => {  //foreach substring in the key
          let abbreviationForWord;
          if (/^\d+$/.test(word)) {  //if substring consists of only digits
            abbreviationForWord = word;  //skip abbreviation step and use original substring as abbreviation
          } else {
            abbreviationForWord = LABEL_ABBREVIATIONS[word]; //check if there's an abbreviation for the substring
            if (!abbreviationForWord) {  //if not, grab the first 3 characters as the abbreviation
              abbreviationForWord = abbreviateToMaxCharacters(word, 3);
            }
          }
          abbreviationsForWord.push(abbreviationForWord);  //add abbreviation (or original substring) to list
        });

        abbreviation = abbreviateToMaxCharacters(abbreviationsForWord.join(' '), 7);  //now, if there were multiple words in the key, there are multiple abbrevations.  Concatenate them and then truncate to 7 characters max
      }
    }

    abbreviationLabels.push(abbreviation);
  });

  return labelStringFor(abbreviationLabels);
}

function labelStringFor(labels: string[]) {
  return labels
    ?.filter(label => label)
    .join(' · ');
}

function abbreviateToMaxCharacters(input: string, abbreviationLength : number) {
  if (input.length > abbreviationLength) {
    input = input.substring(0, abbreviationLength);
    input = input.trim();
  }

  return input;
}