import axios from 'axios';

export default {
  async getModelVersionInfo() {
    const url = String(process.env.VUE_APP_ML_MODEL_VERSION_URL);
    try {
      const response = await axios.get(url);
      return response.data; // Return the version data
    } catch (error) {
      console.error('Error fetching ML model version information:', error);
      throw error;
    }
  }
};
