<template>
  <modal :showModal="showModal" @close-modal="dismissSuccessfully" :contentClass='"w-50"'>
    <template v-slot:header>
      <div class='d-flex justify-content-between'>
        <h4>Edit Scenario</h4>        
      </div>
    </template>

    <template v-slot:main-content>
      <div class="mt-2 loading-title" v-if="jobLevelOptionsIsLoading || metricsLoading">
        Loading scenario information
        <b-spinner small class="ml-2" variant="primary"/>
      </div>

      <div class="scenario-information" v-else>
        <FormulateForm @submit="submitScenarioUpdate">
          <div id="edit-form" v-show="!confirmUpdate">
            <FormulateInput
              name="scenario name"
              label="Scenario Name"
              type="text"
              v-model='newScenarioName'
              validation="required"
              element-class='mt-0 formulate-input-element'
              input-class='border' />
            <div class="details-note mt-1">Use a descriptive name to easily identify the scenario.</div>
            <span v-if="isInboundSimulation">  
              <FormulateInput
                v-show="!isUpdateModal"
                type='radio'
                name='job_level'
                label='Job Level'
                :options='jobLevelOptions.map(level => level.name)'
                :disabled='true'
                v-model='selectedJobLevel'
                element-class='mt-0'
                input-class='job-level-input-class'
                class="job-level"
                validation="required" />
              <FormulateInput
                v-if='selectedJobLevel'
                :label='selectedAnalysisTypesWithCriteria.length==1? "Analysis Type": "Analysis Types"'
                type='group'
                :repeatable='true'
                v-model='selectedAnalysisTypesWithCriteria'
                :minimum='selectedAnalysisTypesWithCriteria.length'
                :limit='selectedAnalysisTypesWithCriteria.length'
                class='no-top-margin-all-descendants'
                @repeatableAdded="analysisTypeAdded"
                #default='{ index }'>
                
                <FormulateInput
                  type='select'
                  name='analysis_type'
                  :label='index === 0 ? "Primary" : "Complementary"'
                  :options='analysisTypeOptions(index)'
                  @change='setSelectedAnalysisType(index)'
                  :disabled='true'
                  element-class='mt-0 formulate-input-element'
                  input-class='d-inline-block px-2 my-0'
                  label-class='text-secondary' />
                
                <b-spinner small v-if="metricsLoading"></b-spinner>
                <FormulateInput v-if="!metricsLoading && showAnalysisSelection"
                  type='checkbox'
                  name='criteria'
                  label='Metrics:'
                  :options='criteriaOptionsForAnalysisType(index)'
                  element-class='mt-0 d-inline-block mb-0'
                  input-class='d-inline-block px-2 my-0 mt-2'
                  @input="(e) => disableIncompatibleCriteriaChoices(index, e)"
                  label-class='d-inline-block text-secondary' 
                />

              </FormulateInput>
            </span>
            <div class="submit-container">
              <custom-button theme='ghost-button' type="button" @click="dismissSuccessfully">Cancel</custom-button>
              <div class="submit-button-container">
                <b-spinner small class="mr-2 mt-2" v-if="updatingScenario" variant="primary"/>
                <div id='scenario-creation-button'>
                  <div v-if="updatesCriterias">
                    <FormulateInput
                      @click="setConfirmUpdate(true)"
                      name="Update scenario"
                      type="button" 
                    />
                  </div>
                  <div v-else>
                    <FormulateInput
                      name="Update scenario"
                      type="submit"
                      :error="errorUpdatingScenario ? 'Error updating scenario' : null"
                      />
                  </div>
                  <b-tooltip 
                    target='scenario-creation-button' 
                    triggers='hover'
                    v-if='!newScenarioName.trim()'>
                    Scenario name cannot be blank.
                  </b-tooltip>
                </div>
              </div>
            </div>
            <b-alert show variant=warning class="mt-2 p-1" v-if="duplicatedScenarioName">
              <b-icon-exclamation-triangle-fill class="mr-2"/>
              <span>Scenario name already exists.</span>
            </b-alert>        
          </div>
          <div id="confirm-update-form" v-show="confirmUpdate">
            <div class="confirmation-update-text">Changing metrics will reset any previously saved simulation paremeters. Do you want to continue with the update?</div>
            <div class="submit-container">
              <custom-button theme='ghost-button' type="button" @click="setConfirmUpdate(false)">Cancel</custom-button>
              <div class="submit-button-container">
                <b-spinner small class="mr-2 mt-2" v-if="updatingScenario" variant="primary"/>
                <div id='scenario-creation-button'>
                  <FormulateInput
                    name="Confirm"
                    type="submit"
                    :error="errorUpdatingScenario ? 'Error updating scenario' : null"
                    /> 
                  <b-tooltip 
                    target='scenario-creation-button' 
                    triggers='hover'
                    v-if='!newScenarioName.trim()'>
                    Scenario name cannot be blank.
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </FormulateForm>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Modal, CustomButton } from 'rwdi-widgets';
import { GET_LOGGED_IN_USER } from '@/store/actions/auth';
import { GET_COMPANY_AVAILABLE_SUBSCRIPTIONS } from '@/store/actions/permissions';

export default {
  name: 'EditScenarioModal',
  components: {
    Modal,
    CustomButton
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    simulationId: {
      type: Number,
      required: false
    },
    configurationId: {
      type: Number,
      required: false
    },
    scenarioName: {
      type: String,
      required: true
    },
    correctSimulation: {
      required: false
    },
    study: {
      required: false  //only needed if the scenario list hasn't been loaded yet
    }
  },
  computed: {    
    project() {
      let project;
      project = this.$store.getters['project/selectedProject'];
      if (project) {
        return project;
      } else {
        let allProjects = this.$store.getters['project/allProjects'];
        project = allProjects.find(proj => proj.id === this.projectId);
        return project;
      }
    },
    canCreateScenario() {
      return (this.allowedPermissions || {});
    },
    companyJobTypes() {
      let typeEnabled, hasRequiredModule;
      return this.jobTypes.filter(type => {
        typeEnabled = (this.companyIsRWDI && type.rwdi) || (!this.companyIsRWDI && type.os_direct);
        hasRequiredModule = (!type.required_module) || (type.required_module && this.hasModuleById(type.required_module));
        return typeEnabled && hasRequiredModule || this.userIsSuperuser;
      });
    },
    jobLevelOptions() {
      if (this.selectedSimulation) {
        let selectedLevel = this.jobTypes.find(job => job.id == this.selectedSimulation?.job_types_with_criteria[0].job_type);
        return [selectedLevel.level];
      }
      return [];
    },
    jobLevelOptionsIsLoading() {
      return this.jobLevelOptions.length == 0 || this.selectedJobLevel == null;
    },
    metricsLoading() {
      if (this.criteria.length == 0) {
        return true;
      } else if (this.selectedSimulation==null) {
        return true;
      }
      return false;
    },
    isUpdateModal() {
      return !!this.formValues;
    },
    maxAnalysisTypes() {
      if(this.selectedAnalysisTypesWithCriteria.length === 0) {
        return 1;
      } else {
        let primaryAnalysisType = this.selectedAnalysisTypesWithCriteria[0].analysis_type;
        if(!primaryAnalysisType) return 1;
        let selectedGroup = this.companyJobTypes.find(type => type.analysis_type === primaryAnalysisType && type.level.name === this.selectedJobLevel).group;
        let numberOfAnalysisTypesInGroup = this.companyJobTypes.filter(type => type.group === selectedGroup && type.level.name === this.selectedJobLevel).length;
        return numberOfAnalysisTypesInGroup;
      }
    },
    complementaryAnalysisTypesAvailable() {
      return this.maxAnalysisTypes > 1;
    },
    showAnalysisSelection(){
      return this.selectedJobLevel != 'Import Results';
    },
    updatesCriterias(){
      let updatingCriteria = false;
      this.initialAnalysisTypeswithCriteria.forEach((item,index) => {
        if (item.criteria.length != this.selectedAnalysisTypesWithCriteria[index].criteria.length) {
          updatingCriteria = true;
        }
      });
      if (updatingCriteria == false) {
        this.initialAnalysisTypeswithCriteria.forEach((item,index) => {
          item.criteria.forEach(criteriaItem => {
            if (!(this.selectedAnalysisTypesWithCriteria[index].criteria.includes(criteriaItem))){
              updatingCriteria = true;
            }
          });
        });
      }
      return updatingCriteria;
    },
    isInboundSimulation(){
      if (this.selectedSimulation) {
        return this.selectedSimulation?.category === 'INBOUND';
      }
      return false;
    },
    ...mapGetters(['allowedPermissions', 'loggedInUser','userCompany', 'companySubscriptions']),
    ...mapGetters('project', ['allProjects', 'uploadUuid', 'jobTypes', 'criteria','inboundScenario', 'selectedProject']),
    ...mapGetters('project/simulationAsset', ['simulation','simulations']),
  },
  data() {
    return {
      modalIsVisible: false,
      newScenarioName: this.scenarioName,
      errorUpdatingScenario: false,
      duplicatedScenarioName: false,
      updatingScenario: false,
      selectedJobLevel: null,
      selectedAnalysisTypesWithCriteria: [],
      initialAnalysisTypeswithCriteria: [],
      disabledCriteria: [],
      selectedAnalysisType: null,
      confirmUpdate: false,
      selectedSimulation: null
    };
  },
  async created() {
    await this.$store.dispatch(GET_LOGGED_IN_USER);
    await this.$store.dispatch(GET_COMPANY_AVAILABLE_SUBSCRIPTIONS);

    if (this.simulations == null) {
      //scenarios list page was loaded for a project without scenarios and the user added a scenario and then immediately clicked edit
      // or
      //user maybe had bookmarked the page after loading it without scenarios.  
      //either way, the url is something like https://appURL/projects/<Project id>/properties?tab=scenarios.  The project details page then didn't 
      //load and the simulations list from the vuex store has been loaded.
      this.$store.dispatch('project/simulationAsset/selectSimulation', this.configurationId);
      await this.$store.dispatch('project/simulationAsset/getSimulations', { projectId: this.selectedProject.id, studyId: this.study.id });
    } 
    if (this.correctSimulation) {
      this.simulations?.forEach(sim => {
        if (sim.id == this.simulationId) {
          this.selectedSimulation = sim;
          let selectedLevel = this.jobTypes.find(job => job.id == this.selectedSimulation?.job_types_with_criteria[0].job_type);
          this.selectedJobLevel = selectedLevel.level.name;
        }
      });
    } else {
      this.selectedSimulation = this.simulation;
      let selectedLevel = this.jobTypes.find(job => job.id == this.selectedSimulation?.job_types_with_criteria[0].job_type);
      this.selectedJobLevel = selectedLevel.level.name;
    }
  },
  methods: {
    addTooltip(element, tooltip) {
      let tooltipSpan = document.createElement('span');
      tooltipSpan.classList.add('criteria-tooltip');
      let tooltipText = document.createTextNode(tooltip);
      tooltipSpan.appendChild(tooltipText);
      element.appendChild(tooltipSpan);
      element.addEventListener('mouseover', this.showCriteriaTooltip);
      element.addEventListener('mouseout', this.hideCriteriaTooltip);
    },
    removeTooltip(element) {
      let tooltipSpan = element.querySelector('.criteria-tooltip');
      element.removeChild(tooltipSpan);
      element.removeEventListener('mouseover', this.showCriteriaTooltip);
      element.removeEventListener('mouseout', this.hideCriteriaTooltip);
    },
    async submitScenarioUpdate(data) {
      let scenarioCreationInfo = null;      
      scenarioCreationInfo = await this.updateScenario(data);
      if (scenarioCreationInfo === null) {
        this.dismissSuccessfully();
      }
    },
    async updateScenario() {
      try {
        this.updatingScenario = true;
        let updateScenarioObject = {
          name: this.newScenarioName,
          simulation_id: this.simulationId
        };
        if (this.isInboundSimulation) {
          updateScenarioObject['job_types_with_criteria'] = this.selectedJobTypesWithCriteria;
          if (this.updatesCriterias == true) {
            updateScenarioObject['clear_cfd_parameters'] = true;
          }
        }
        await this.storeUpdateScenario({
          projectId: Number(this.$route.params.id),
          studyId: this.study ? this.study.id : this.$route.params.study,
          configurationId: this.configurationId,
          scenario: updateScenarioObject
        });
        this.updatingScenario = false;
        this.dismissSuccessfully();
        this.$router.go();
        return {};
      } catch (error) {
        return null;
      }
    },
    dismissSuccessfully() {
      this.$emit('close-modal');
    },
    setConfirmUpdate(stats) {
      this.confirmUpdate = stats;
    },
    hasModuleById(moduleId) {
      let companyHasModule = !!this.userCompany.modules.find(x => x.id === moduleId);
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.id === moduleId);
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    setSelectedAnalysisType(index) {
      let selectedAnalysisType = this.selectedAnalysisTypesWithCriteria[index].analysis_type;
      this.selectedAnalysisType = selectedAnalysisType;
      let selectedJobType = this.companyJobTypes.find(type => type.analysis_type === selectedAnalysisType && type.level.name == this.selectedJobLevel);
      if(selectedJobType?.default_criteria_group?.length){
        this.selectedAnalysisTypesWithCriteria[index].criteria = selectedJobType.default_criteria_group;
      }else{
        this.selectedAnalysisTypesWithCriteria[index].criteria = selectedJobType.default_criteria;
      }
      this.selectedAnalysisTypesWithCriteria[index].group = selectedJobType.group;

      if (index === 0 ) {
        //primary analysis type changed.  check to see if complementary analysis types exist for the selected primary type.  If not
        //the remove any complementary types that had been added
        if (!this.complementaryAnalysisTypesAvailable) {
          this.selectedAnalysisTypesWithCriteria.length = 1;  //drop all but the first element of the list
        }
      }
    },
    async analysisTypeAdded(analysisTypesList) {
      await this.$nextTick();  //let this.selectedAnalysisTypesWithCriteria update with the newly added analysis type, then that type is used to get the default criteria
      let addedIndex = analysisTypesList.length - 1;
      this.setSelectedAnalysisType(addedIndex);
    },
    analysisTypeOptions(index) {
      if (index === undefined) return [];
  
      let analysisTypeOptions = [];

      if (index === 0) {
        this.jobTypes.forEach(type => {
          if(type.id == this.selectedSimulation?.job_types_with_criteria[0].job_type) {
            analysisTypeOptions.push({
              label: type.label,
              value: type.analysis_type
            });
          }
        });
      } else {
        this.jobTypes.forEach(type => {
          if(type.id == this.selectedSimulation?.job_types_with_criteria[1].job_type) {
            analysisTypeOptions.push({
              label: type.label,
              value: type.analysis_type
            });
          }
        });
      }
      
      return analysisTypeOptions;
    },
    criteriaOptionsForAnalysisType(index) {
      if(this.selectedAnalysisTypesWithCriteria.length === 0 || index === undefined) return [];

      let analysisType = this.selectedAnalysisTypesWithCriteria[index].analysis_type;
      let criteriaOptions = [];
      this.criteria.forEach(criterion  => {
        if (!criterion.required_module || criterion.required_module && this.hasModuleById(criterion.required_module)) {
          if(criterion.analysis_type === analysisType && criterion.required_level.includes(this.selectedJobLevel)){
            if(!criterion.display_group) {
              criteriaOptions.push({'name': criterion.name, 'sort_order': this.sortOrderOrDefault(criterion.sort_order)});
            } else {
              let displayGroupPresent = criteriaOptions.find(c => c.name == criterion.display_group);
              if (!displayGroupPresent) {
                criteriaOptions.push({name: criterion.display_group, sort_order: this.sortOrderOrDefault(criterion.sort_order)});
              }
            }
          }
        }
      });
      const sortedCriteriaOptions = criteriaOptions.sort(function(a, b){return a.sort_order - b.sort_order ;});
      const criteriaOptionsList = sortedCriteriaOptions.map(function (criterion) {
        return criterion.name;
      });
      return criteriaOptionsList;
    },
    sortOrderOrDefault(sortOrder) {
      if (!sortOrder) {
        return 99999; 
      } else return sortOrder;
    },
    async disableIncompatibleCriteriaChoices(index, selectedCriteriaNames) {
      //enable anything that's been previously disabled
      for (let disabled_item_id of this.disabledCriteria) {
        let disabled_item_label = document.querySelector(`[id^="${disabled_item_id}"]`);
        if(disabled_item_label) {
          disabled_item_label.parentElement.classList.remove('disabled');
          this.removeTooltip(disabled_item_label.parentElement.parentElement); // the label's parent is disabled and the label's grandparent contains the tooltip
        }
      }

      this.disabledCriteria.length = 0;  //reset the array since we've just enabled everything in it

      //disable any that are incompatible with the selected criteria
      for (let selectedCriteria of selectedCriteriaNames) {

        //go through the master list of criteria and find the object that corresponds to the selected criteria name and selected job level (SPMV*, for example, has 2 entries: 1 for AI and 1 for CFD)
        let matching_criteria_object = this.criteria.find(criterion => criterion.name == selectedCriteria && criterion.required_level.includes(this.selectedJobLevel));

        if (matching_criteria_object?.incompatible_criteria) {  //matching_criteria_object will be null if the selected criteria is actually a criteria group.  At the moment support for incompatible criteria groups has not been implemented and is not needed.
          //loop through the selected criteria options and for each,check if any of the other criteria options are in the selected criteria's list of incomptatible criteria.  If so, disable those options so they can't be clicked
          for (let incompatible_criteria_entry of matching_criteria_object.incompatible_criteria) {
            if (this.criteriaOptionsForAnalysisType(index).includes(incompatible_criteria_entry)) {
              await this.$nextTick();  //when the selected analysis type changes this method fires before the new analysis type's criteria list is available.  nextTicket lets the UI re-render before proceeding

              //the label element of the checkbox contains the criteria name and has a _label at the end of it, so use that to get the element, then 
              //get the elements parent, which is a div containing checkbox control.  Then disable it.
              let label_element = document.querySelector(`[id$="${incompatible_criteria_entry}_label"]`);
              label_element.parentElement.classList.add('disabled');
              this.disabledCriteria.push(label_element.id);  //store the disabled items so they can be re-enabled
              
              //the label's parent has been disabled, so mouse events won't propagate, but we can add a tooltip to the control's grandparent, which is another contain for the control.  This allows
              //the mouse event for the tooltip to work while maintaining a disabled input/label
              this.addTooltip(label_element.parentElement.parentElement, `uncheck "${selectedCriteria}" to enable this metric`);
            }
          }
        }
        
      }
    },
    ...mapActions({
      storeUpdateScenario: 'project/updateScenario',
    })
  },
  watch: {
    selectedAnalysisTypesWithCriteria(newValue) {
      let selectedJobTypesWithCriteria = [];
      let expandedSelectedAnalysisTypesWithCriteria = [];
      let jobType, criteria, isDisplayGroup, criteriaInDisplayGroup;

      newValue.forEach(analysisWithCriteriaOrDisplayGroup => {
        criteria = [];
        if(analysisWithCriteriaOrDisplayGroup.criteria) {
          analysisWithCriteriaOrDisplayGroup.criteria.forEach(criterion => {
            isDisplayGroup = this.criteria.find(c => c.display_group == criterion && c.required_level.includes(this.selectedJobLevel));
            if(isDisplayGroup) {
              criteriaInDisplayGroup = this.criteria.filter(c => c.display_group == criterion && c.required_level.includes(this.selectedJobLevel));
              criteria = criteria.concat(criteriaInDisplayGroup.map(c=>c.name));
            } else {
              criteria.push(criterion);
            }
          });
        }
        expandedSelectedAnalysisTypesWithCriteria.push({
          analysis_type: analysisWithCriteriaOrDisplayGroup.analysis_type,
          criteria: criteria
        });
      });

      expandedSelectedAnalysisTypesWithCriteria.forEach(analysisWithCriteria => {
        jobType = this.jobTypes.find(type => type.analysis_type === analysisWithCriteria.analysis_type && type.level.name === this.selectedJobLevel);
        if(analysisWithCriteria.criteria) {
          criteria = analysisWithCriteria.criteria.map(criterion => {
            return this.criteria.find(c => c.name === criterion && c.required_level.includes(this.selectedJobLevel))?.id;
          });
        } else {
          criteria = [];
        }
        if(jobType?.id) {
          // using snake case since this will be the value saved to the back-end db
          selectedJobTypesWithCriteria.push({
            job_type: jobType.id,
            criteria: criteria
          });
        }
      });
      this.expandedSelectedAnalysisTypesWithCriteria = expandedSelectedAnalysisTypesWithCriteria;
      this.selectedJobTypesWithCriteria = selectedJobTypesWithCriteria; 
    },
    selectedJobLevel(newValue) {
      if(newValue) {
        this.selectedJobTypesWithCriteria = [];
        let firstAnalysisType = this.analysisTypeOptions(0)[0];
        let firstJobType = this.companyJobTypes.find(type => type.level.name === newValue && type.analysis_type === firstAnalysisType.value);
        let selectedCriteriaNames = [];
        this.criteria.forEach(currentCriteria => {
          if ((this.selectedSimulation?.job_types_with_criteria[0].criteria).includes(currentCriteria.id)) {
            if(!currentCriteria.display_group) {
              selectedCriteriaNames.push(currentCriteria.name);
            } else {
              if (!selectedCriteriaNames.includes(currentCriteria.display_group)) {
                selectedCriteriaNames.push(currentCriteria.display_group);
              }
            }
          }
        });

        this.selectedAnalysisTypesWithCriteria = [{ analysis_type: firstAnalysisType.value, criteria: selectedCriteriaNames, group: firstJobType.group }];
        this.selectedAnalysisType = firstAnalysisType.value;

        if (this.selectedSimulation?.job_types_with_criteria.length == 2) {
          let secondAnalysisType = this.analysisTypeOptions(1)[0];
          let secondJobType = this.jobTypes.find(type => type.level.name === newValue && type.analysis_type === secondAnalysisType.value);
          selectedCriteriaNames = [];
          this.criteria.forEach(currentCriteria => {
            if ((this.selectedSimulation?.job_types_with_criteria[1].criteria).includes(currentCriteria.id)) {
              if(!currentCriteria.display_group) {
                selectedCriteriaNames.push(currentCriteria.name);
              } else {
                if (!selectedCriteriaNames.includes(currentCriteria.display_group)) {
                  selectedCriteriaNames.push(currentCriteria.display_group);
                }
              }
            }
          });
          this.selectedAnalysisTypesWithCriteria.push({ analysis_type: secondAnalysisType.value, criteria: selectedCriteriaNames, group: secondJobType.group });
        }

        if (this.initialAnalysisTypeswithCriteria.length === 0) {
          this.selectedAnalysisTypesWithCriteria.forEach(item => {
            this.initialAnalysisTypeswithCriteria.push(item);
          });
        }

        let creditType = this.companyJobTypes.find(type => type.level.name === newValue).credit_type;
        if (creditType === 'NONE') {
          this.selectedSimulationType = 'IMPORT';
        } else if (creditType === 'AI') {
          this.selectedSimulationType = 'ML';
        } else if (creditType === 'CFD') {
          this.selectedSimulationType = 'CFD';
        }
      }
    },
  }
};
</script>

<style scoped>
.radio-button-disabled {
  opacity: 0.5;
}

.submit-container {
  display: flex;
}

.submit-container > * {
  margin-top: 0;
}

.loading-view {
  display: flex;
  align-items: center;
}

.formulate-input {
  margin-bottom: 0;
}

.small-text {
  font-size: 0.625rem;
}

.small-label {
  font-size: .9em;
}

.submit-container {
  padding-top: 1rem;
  padding-right: 0.2rem;
}
</style>

<style>
.criteria-tooltip {
   display: none;
   background-color: rgb(103, 102, 102);
   color: rgb(255, 255, 255);
   font-size: 0.75rem;
   position: absolute;
   margin-top: -3.5rem;
   margin-left: 0.5rem;
   padding-left: 0.35rem;
   padding-right: 0.35rem;
   border-radius: 0.35rem;
}

[id^=checkbox-disabled] {
  opacity: 0.5;
}

.subscription-radios .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff !important;
}

.level-radios .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff !important;
}

.formulate-input .formulate-input-element {
  max-width: none;
}

.formulate-input[data-classification=button] button {
  background-color: var(--primary-blue);
  border: 0.063rem solid var(--primary-blue-dark);
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=button] button:active {
  background-color: var(--primary-blue);
  border: 0.063rem solid var(--primary-blue-dark);
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=file] .formulate-input-upload-area input:focus~.formulate-input-upload-area-mask, .formulate-input[data-classification=file] .formulate-input-upload-area input:hover~.formulate-input-upload-area-mask, .formulate-input[data-classification=file] .formulate-input-upload-area input[data-is-drag-hover]~.formulate-input-upload-area-mask {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.formulate-input[data-classification=file] .formulate-input-upload-area input:focus~.formulate-input-upload-area-mask:before, .formulate-input[data-classification=file] .formulate-input-upload-area input:hover~.formulate-input-upload-area-mask:before, .formulate-input[data-classification=file] .formulate-input-upload-area input[data-is-drag-hover]~.formulate-input-upload-area-mask:before {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
  background-color: var(--primary-blue);
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-add {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}


.submit-container {
  display: flex;
  margin-top: 0;
  justify-content: space-between;
  align-items: center;
}

.submit-container > * {
  margin-top: 0;
}

.submit-button-container {
  display: flex;
}

.submit-button-container > * {
  margin: 0;
}

.loading-view {
  display: flex;
  align-items: center;
}

.details-note {
    color: #6d6d6d;
    font-size: .7em;
    font-weight: 400;
    line-height: 1.5;
}

hr {
  height: 0.125rem;
  margin-bottom: 1.563rem;
  margin-top: 1.563rem;
}

#edit-form {
  max-height: 36rem;
  overflow-y: auto;
}

@media (max-width: 1450px) {
  #edit-form {
    max-height: 30rem;
  }
}

@media (max-width: 1300px) {
  #edit-form {
    max-height: 27rem;
  }
}

#confirm-update-form{
  height: 6rem;
  padding-top: 0rem;
}

.confirmation-update-text {
  color: #6d6d6d;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 1rem;
}

.main-content {
  margin-top: 0.7rem;
}

.main-content .loading-title{
  padding-top: 1.3rem;
  padding-bottom: 1rem;
  text-align: center;
}

.job-level {
  margin-top: 0.5rem;
}

.job-level-input-class .formulate-input-label--after {
  margin-top: 0rem;
}

.job-level-input-class .formulate-input-element-decorator{
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0.5rem;
}
</style>
