<template>
  <div class="canvas-wrapper">
    <div :class="['toolbar-container']">
      <viewer-toolbar
        :rank='rank'
        :showCameraControls="showRefPicker"
        :themeLabels="this.themeLabels"
        :showProbeMode="showProbeMode"
        :selectedThemeIndex="this.selectedThemeIndex"
        :savedCameraPositions="this.savedCameraPositions"
        :insightCameraPositions="camerasWithInsights"
        :deletingCameraPosition="this.deletingCameraPosition"
        :activeCameraView="this.activeCameraView"
        :screenShotMode="this.screenShotMode"
        :clippingPlaneMode="this.clippingPlaneMode"
        :clippingPlaneHeight="this.clippingPlaneHeight"
        :geometryHeight="this.geometryHeight"
        :geometryFloor="this.geometryFloor"
        :layerIsLoading='layerIsLoading'
        :graphAssets='this.graphAssets'
        :selectedSimulationLabel="this.simulationLabel"
        :showSimEditButton="simEditButtonStatus"
        @setSelectedThemeIndex="setSelectedThemeIndex"
        @setCameraView="setCameraView"
        @setDataProbeStatus="setDataProbeStatus"
        @editSimulation="editSimulation"
        @setCamera="showCameraPosition()"
        @addCamera="cameraPositionModalVisible.create = true"
        @renameCamera="cameraPositionModalVisible.update = true"
        @deleteCamera="deleteCameraPosition()"
        @resetCamera="resetCamera()"
        @toggleScreenShotMode="toggleScreenShotMode()"
        @toggleClippingPlaneMode="toggleClippingPlaneMode()"
        @setClippingPlaneHeight="setClippingPlaneHeight"
        @addInsightCamera="addInsightCamera()"
        @createNewInsight="createNewInsight"
        @saveSceneForNewInsight="saveSceneForNewInsight">
      </viewer-toolbar>
    </div>
    <div id="screenshot_controls">
      <screen-shot-controls
        v-show="this.screenShotMode"
        class="draggable-modal"
        :showScreenShotControls="screenShotMode"
        :aspectX="this.aspectX"
        :aspectY="this.aspectY"
        :screenShotSize="this.screenShotSize"
        :onSavedCamera="this.onSavedCamera"
        @setAspectX="setAspectX"
        @setAspectY="setAspectY"
        @setScreenShotSize="setScreenShotSize"
        @saveScreen="saveScreen"
        @setProjectImage="setProjectImage"
        @takeMultipleScreenshots="takeMultipleScreenshots"
        @toggleScreenShotMode="toggleScreenShotMode()">
      </screen-shot-controls>
    </div>
    <div id="probeTypeDisplay" class="mt-2" v-if="activeProbeType != null">
      <b-alert show variant="info">
        Click to place {{activeProbeType}} probes
      </b-alert>
    </div>
    <div id="sceneCompositionModeDisplay" class="mt-2" v-show="sceneViewCompositionMode">
      <b-alert show variant="info">
        Compose a scene for your insight, then click 'Save Scene' to continue
      </b-alert>
    </div>
    <div id="annotationCompositionDisplay" class="mt-2" v-if="annotationsCompositionMode">
      <b-alert show variant="info">
        Click to place an insight annotation
        <b-icon-x
          variant="danger"
          @click="closeAnnotationComposition"
          style="cursor: pointer;"></b-icon-x>
      </b-alert>
    </div>
    <div id="processingInProgressDisplay" class="mt-2 no-top-margin-all-descendants" v-if="showProcessingInProgressDisplay">
      <b-alert show>
        <div>
          <div v-if="isViewerMode && waitingForAdditionalResults && !moreResultsAvailable">
            <span>Post processing in progress.  More results available soon.</span>
            <b-spinner small class="mx-2"></b-spinner>
          </div>
          <div v-if="isViewerMode && waitingForResults && !moreResultsAvailable">
            <span>{{mlStatus}}</span>
            <b-spinner small class="mx-2"></b-spinner>
          </div>
          <div v-if="moreResultsAvailable && isViewerMode">
              <span>{{newResultsAvailableText}}</span><b-button small variant="outline-primary" class="ml-2 p-1" @click="goToResults()">Reload</b-button>
          </div>
        </div>
      </b-alert>
    </div>
    <div id="simulationSettingsPopupMenu" :class="this.isSimulationInbound ? 'controls-info-container displaced' : 'controls-info-container nondisplaced'">
      <b-toast :id="`${rank}-sim-settings-menu`" static no-auto-hide @hidden="simulationSettingsClosed">
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-baseline" ref="simToastHeader">
            <strong id="sim-settings-header">Simulation Settings</strong>
          </div>
        </template>
          <div id="toast-container" ref="simToastBody">
            <div id="sim-settings-ul-container">
              <ul class='sim-settings-ul list-group'  :class="this.userIsSuperuser ? '' : 'no-conf-index'">
                <li v-if="simulationType" @click="selectDetail('simulation_type', simulationType)" :class="this.simSettingsInfo == 'simulation_type' ? 'selected': ''">
                  <span> Simulation Type</span>
                </li>
                <li v-if="met_source" @click="selectDetail('met_source', met_source)" :class="this.simSettingsInfo == 'met_source' ? 'selected': ''">
                  <span> Weather Data</span>
                </li>
                <li key="analysis_types" v-if="analysis_types" @click="selectDetail('analysis_types', analysis_types)" :class="this.simSettingsInfo == 'analysis_types' ? 'selected': ''">
                  <span> Analysis Types and Metrics</span>
                </li>
                <li v-if="seasons" @click="selectDetail('seasons', seasons)" :class="this.simSettingsInfo == 'seasons' ? 'selected': ''">
                  <span> Seasons</span>
                </li>
                <li v-if="simulation_times" @click="selectDetail('simulation_times', simulation_times)" :class="this.simSettingsInfo == 'simulation_times' ? 'selected': ''">
                  <span> Times of Day</span>
                </li>
                <li v-if="activity_level" @click="selectDetail('activity_level', activity_level)" :class="this.simSettingsInfo == 'activity_level' ? 'selected': ''">
                  <span> Activity Level</span>
                </li>
                <li v-if="clothing_profile" @click="selectDetail('clothing_profile',clothing_profile)" :class="this.simSettingsInfo == 'clothing_profile' ? 'selected': ''">
                  <span> Clothing Profile</span>
                </li>
                <li v-if="windDirections" @click="selectDetail('wind_direction', windDirections)" :class="this.simSettingsInfo == 'wind_direction' ? 'selected': ''">
                  <span> Wind Directions & Terrain</span>
                </li>
                <!-- Only superusers have access to the confidence index -->
                <li v-if="(this.userIsSuperuser && this.isML && this.simulationSettings.confidenceIndex)" @click="selectDetail('confidence_index', confidenceIndex)" :class="this.simSettingsInfo == 'confidence_index' ? 'selected': ''">
                  <span> Confidence Index</span>
                </li>
                <li v-if="showbuildingRoofHeight" @click="selectDetail('roof_height', showbuildingRoofHeight)" :class="this.simSettingsInfo == 'roofHeight' ? 'selected': ''">
                  <span> Building Height</span>
                </li>
                <li v-if="getCompletedDate" @click="selectDetail('simulation_date', getCompletedDate)" :class="this.simSettingsInfo == 'simulation_date' ? 'selected': ''">
                  <span> Simulation Date </span>
                </li>
                <li v-if="getSoftwareVersion" @click="selectDetail('software_version', getSoftwareVersion)" :class="this.simSettingsInfo == 'software_version' ? 'selected': ''">
                  <span> Software Version </span>
                </li>
                <li v-if="showInternalPressure" @click="selectDetail('internal_pressure', showInternalPressure)" :class="this.simSettingsInfo == 'internal_pressure' ? 'selected': ''">
                  <span> Internal Pressure </span>
                </li>
                <li v-if="showBuildingEnclosure" @click="selectDetail('building_enclosure', showBuildingEnclosure)" :class="this.simSettingsInfo == 'building_enclosure' ? 'selected': ''">
                  <span> Building Enclosure</span>
                </li>
                <li v-if="showReturnPeriods" @click="selectDetail('return_period', showReturnPeriods)" :class="this.simSettingsInfo == 'return_period' ? 'selected': ''">
                  <span> Return Periods </span>
                </li>
                <li v-if="showMinutesInterval" @click="selectDetail('minutes_interval', showMinutesInterval)" :class="this.simSettingsInfo == 'minutes_interval' ? 'selected': ''">
                  <span> Analysis Interval </span>
                </li>
                <li v-if="showStructuralOrigin" @click="selectDetail('structural_origin', showStructuralOrigin)" :class="this.simSettingsInfo == 'structural_origin' ? 'selected': ''">
                  <span> Structural Origin </span>
                </li>
                <li v-if="showFrontalAreas" @click="selectDetail('frontal_areas', showFrontalAreas)" :class="this.simSettingsInfo == 'frontal_areas' ? 'selected': ''">
                  <span> Frontal Areas </span>
                </li>
                <li v-if="scenario_note" @click="selectDetail('scenario_note',scenario_note)" :class="this.simSettingsInfo == 'scenario_note' ? 'selected': ''">
                  <span> Scenario Note</span>
                </li>
                <li @click="selectDetail('keyboard')" :class="this.simSettingsInfo == 'keyboard' ? 'selected': ''">
                  <span> Keyboard Controls</span>
                </li>
                <li v-if="showMetDownload" @click="selectDetail('show_met_download', showMetDownload)" :class="this.simSettingsInfo == 'show_met_download' ? 'selected': ''">
                  <span> Weather Data</span>
                </li>
              </ul>
            </div>
        </div>
      </b-toast>
      <simulation-setting-item
        v-show="showSettingDetail"
        v-on:closeKeyboardMenu="closeKeyboard($event)"
        :settingType="this.simSettingsInfo"
        :settingValue=settingValue
        :toastHeight=simToastHeight
        :onlyShowKeyboardControls="this.onlyShowKeyboardControls"
        :isCladding=contains_cladding
       />
      <button class="controls-info-button" @click="showToast" v-if="menuIconVisible">
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
          <g transform="translate(0.5 0.5)">
            <circle cx="18" cy="18" r="18"/>
            <g transform="translate(14012 -3586.599)">
              <path d="M17.2,5.327a4.477,4.477,0,1,0-6.268,4.1V11.6h3.582V9.43a4.474,4.474,0,0,0,2.686-4.1Z" transform="translate(-14006.75 3596.25)"/>
              <path d="M10.5,12.1h3.582" transform="translate(-14006.313 3598.433)"/>
              <path d="M10.5,12.1h3.582" transform="translate(-14006.313 3600.996)"/>
            </g>
          </g>
        </svg><span class="visually-hidden">Controls Info</span>
      </button>
    </div>
    <div id="compass_container" @click="orientCameraNorth()">
      <svg :id="`${rank}_compass`" ref="compass" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0rem" y="0rem" viewBox="0 0 76.5 76.5" style="enable-background:new 0 0 76.5 76.5;" xml:space="preserve">
        <g id="Group_3389" transform="translate(417.979 -595.212)">
          <path id="Path_3337" class="st0" d="M-379.7,595.2c21.1,0,38.2,17.1,38.2,38.2c0,21.1-17.1,38.2-38.2,38.2
            c-21.1,0-38.2-17.1-38.2-38.2C-418,612.3-400.8,595.2-379.7,595.2z M-379.7,663.4c16.5,0,29.9-13.4,29.9-29.9
            c0-16.5-13.4-29.9-29.9-29.9c-16.5,0-29.9,13.4-29.9,29.9C-409.7,650-396.3,663.4-379.7,663.4z"/>
          <path id="Path_3338" class="st1" d="M-379.7,596.8c20.2,0,36.7,16.4,36.7,36.7c0,20.2-16.4,36.7-36.7,36.7
            c-20.2,0-36.7-16.4-36.7-36.7C-416.4,613.2-400,596.8-379.7,596.8z M-379.7,664.9c17.4,0,31.5-14.1,31.5-31.5
            c0-17.4-14.1-31.5-31.5-31.5c-17.4,0-31.5,14.1-31.5,31.5C-411.2,650.8-397.1,664.9-379.7,664.9z"/>
          <g id="Group_3388" class="st2">
            <g id="Group_3388-2">
              <path id="Path_3339" d="M-368.8,613.4c0.9,0,1.7,0.4,2.3,1.1c0.5,0.7,0.7,1.6,0.5,2.5l-10.9,38.3c-0.3,1.2-1.5,2.1-2.7,2.1
                c-1.3,0-2.4-0.8-2.7-2.1l-10.9-38.3c-0.2-0.9-0.1-1.8,0.5-2.5c0.5-0.7,1.4-1.1,2.3-1.1c0.5,0,0.9,0.1,1.3,0.3l9.6,5.1l9.6-5.1
                C-369.7,613.5-369.3,613.4-368.8,613.4z"/>
            </g>
          </g>
          <path id="Path_3340" class="st1" d="M-367.6,616.6l-10.9,38.3c-0.2,0.7-0.9,1.1-1.6,0.9c-0.4-0.1-0.8-0.5-0.9-0.9l-10.9-38.3
            c-0.2-0.7,0.2-1.4,0.9-1.6c0.3-0.1,0.7-0.1,1,0.1l10.3,5.5l10.3-5.5c0.6-0.3,1.4-0.1,1.7,0.5C-367.5,615.9-367.5,616.3-367.6,616.6
            z"/>
        </g>
      </svg>
    </div>
    <div id="color-legend-container" v-if="!isSimulationInbound">
      <div id="colour-scale-container" class="d-flex flex-row no-top-margin-all-descendants align-items-end">  
        <popup v-show="colorScaleMenuVisible && this.horizontalColorScale != null" class="colour-scale-menu" title='Horizontal Color Legend' @close="colorScaleMenuVisible = false">
            <color-scale v-for="(colorScaleData, index) in this.horizontalColorScale"
            :scale-type="colorScaleData.layer_type ? colorScaleData.layer_type : ''"
            :color-map="colorScaleData.color_map || colorScaleData"
            :key="index" />
        </popup>
        <popup v-show="volColorScaleMenuVisible &&  this.volumetricColorScale != null" class="colour-scale-menu" title='Volumetric Color Legend' @close="volColorScaleMenuVisible = false">
          <color-scale v-for="(colorScaleData, index) in this.volumetricColorScale"
            :scale-type="colorScaleData.layer_type ? colorScaleData.layer_type : ''"
            :color-map="colorScaleData.color_map || colorScaleData"
            :key="index" />
        </popup>
        <button class="colour-scale-button" v-if="showColorLegendButton" name="open-color-scales-button" @click="openColorScales" title="Legend">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g class="a">
              <circle class="d" cx="18" cy="18" r="18"/>
              <circle class="e" cx="18" cy="18" r="17.5"/>
            </g>
            <g transform="translate(13857 -4874.5)">
              <g class="b" transform="translate(-13847 4892.307)">
                <rect class="d" width="6.194" height="6.194"/>
                <rect class="e" x="0.75" y="0.75" width="4.694" height="4.694"/>
              </g>
              <g class="b" transform="translate(-13841.889 4892.307)">
                <rect class="d" width="6.133" height="6.194"/>
                <rect class="e" x="0.75" y="0.75" width="4.633" height="4.694"/>
              </g>
              <g class="b" transform="translate(-13836.778 4892.307)">
                <rect class="d" width="6.133" height="6.194"/>
                <rect class="e" x="0.75" y="0.75" width="4.633" height="4.694"/>
              </g>
              <line class="c" y1="3.871" transform="translate(-13846.613 4886.5)"/>
              <line class="c" y1="1.935" transform="translate(-13841.193 4888.436)"/>
              <line class="c" y1="1.935" transform="translate(-13835.774 4888.436)"/>
              <line class="c" y1="3.871" transform="translate(-13831.129 4886.5)"/>
            </g>
          </svg>
          <span class="visually-hidden">Colour Scale Legend</span>
        </button>
      </div>
      <div :class="['save-camera-container', this.showCameraPositionModal ? 'visible' : 'hidden']">

        <b-modal v-model="cameraPositionModalVisible.create" id="modal-1"
          hide-header-close
          title="Save Camera Angle"
          ok-title="Save"
          @ok="saveCameraPosition()">
            <input type="text" placeholder="Saved camera" v-model="currentCameraName">
        </b-modal>

        <b-modal v-model="cameraPositionModalVisible.update" id="modal-2"
          hide-header-close
          title="Rename camera position"
          ok-title="Save"
          @ok="updateCameraPosition()">
            <input type="text" placeholder="New camera name" v-model="newCameraName">
        </b-modal>

      </div>
      <div :class="['windrose-container', windroseModalVisible ? 'visible' : 'hidden']">
        <popup class="windrose-menu" title='' @close="windroseModalVisible = false">
          <div v-if="windroseAvailable" >
            <windrose v-if="currentWindrose != null"
            :windrose=currentWindrose
            :metSourceId="simulation.met_source_id"/>
          </div>
          <div v-else class="no-data no-windrose">
            <span>No wind rose available.</span>
          </div>
        </popup>
        <button :class="windroseAvailable ? ['windrose-button'] : ['windrose-button', 'disabled']" @click="windroseModalVisible = !windroseModalVisible" :title="this.windroseButtonTitle">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="Group_3336" data-name="Group 3336" transform="translate(-1052 -8768)">
              <g id="Ellipse_76" data-name="Ellipse 76" transform="translate(1052 8768)" fill="#fff" stroke="#e2e6e9" stroke-width="1">
                <circle cx="18" cy="18" r="18" stroke="none"/>
                <circle cx="18" cy="18" r="17.5" fill="none"/>
              </g>
              <g id="Group_3328" data-name="Group 3328" transform="translate(1056.625 8770.614)">
                <path id="Path_3320" data-name="Path 3320" d="M63.646,37.159l-2.4-6.534,2.4,1.437,2.4-1.437Z" transform="translate(36.755 80.288) rotate(-135)" fill="none" stroke="#737e87" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Path_3321" data-name="Path 3321" d="M4.375,118.125H14.506a1.511,1.511,0,0,1,1.447,1.567h0a1.511,1.511,0,0,1-1.447,1.567h-.724" transform="translate(-1.8 -96.472)" fill="none" stroke="#737e87" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Path_3322" data-name="Path 3322" d="M4.375,86.259H9.441a1.511,1.511,0,0,0,1.447-1.567h0a1.511,1.511,0,0,0-1.447-1.567H8.717" transform="translate(-1.8 -67.74)" fill="none" stroke="#737e87" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Path_3323" data-name="Path 3323" d="M39.856,18.8a7.334,7.334,0,1,0-9.231-7.086,7.415,7.415,0,0,0,.107,1.256" transform="matrix(0.966, 0.259, -0.259, 0.966, -18.289, -9.429)" fill="none" stroke="#737e87" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </g>
            </g>
          </svg>
          <span class="visually-hidden">Windrose Legend</span>
        </button>
      </div>
      <div :class="['reviewed-by-rwdi-container', this.reviewedByRWDIInfoModalVisible ? 'visible' : 'hidden']">
        <button v-if="this.showReviewedByRWDIButton" class="reviewed" @click="reviewedByRWDIInfoModalVisible = true">Reviewed</button>
        <b-modal centered :hide-footer="true" v-model='reviewedByRWDIInfoModalVisible'>
          <template #modal-header>
            <div class="reviewed-by-rwdi-header-content">
              <h4>What is RWDI Reviewed?</h4>
            </div>
            <button class="close-button" @click="reviewedByRWDIInfoModalVisible = false"><span class='visually-hidden'>Close</span></button>
          </template>
          <template #default>
            <p>The visualized results presented here have been produced by RWDI based
            on the simulation output from Orbital Stack. RWDI's consultants use
            their extensive experience to provide the most realistic and
            representative prediction of the wind microclimate. To achieve this,
            RWDI will sometimes make adjustments to the raw output, based on
            professional judgement.</p>
          </template>
        </b-modal>
      </div>
    </div>
    <!-- this is covered by the viewer.  Under what conditions is there no viewer and we see this?  -->
    <div id="no-assets-to-display-msg" class="no-assets" v-if="!geometryFilesUploaded && isSimulationInbound && isViewerMode">
      <p>No assets. Add geometry to get started.</p>
    </div>
    <div class="reference-origin-outer" v-show="showRefPicker && showViewer && createScenarioStep == 2">      
      <reference-origin-point
        :currentProbePosition="currentRefPoint"
        :refPickerIndex="refPickerIndex"
        @showSimParams="showSimParams"
        @setAxesHelper="setAxesHelper"
        @removeAxesHelper="removeAxesHelper"
        @setCameraView="setCameraView"
      />
    </div>
    <div id="sim-parameters-container" v-if="geometryFilesUploaded && isSimulationInbound && !isJobTypeImport" class="mt-0"> 
      <select-simulation-parameters-panel 
        v-if="parametersPanelCreated" 
        :statusIndex="statusIndex"
        @setRefPicker="setRefPicker"
        v-show="createScenarioStep > 1"
       />
    </div>
    <div id="import-results-container" v-if="geometryFilesUploaded && isSimulationInbound && isJobTypeImport">
      <import-results-file
        v-show="createScenarioStep > 1"
        @setSubmitStatus="setSubmitStatus"
        @setmetaData="setmetaData"
      />
    </div>
    <div id="inbound-scenario-steps-container" class="scenario-submissions-container" v-if="isSimulationInbound && isViewerMode">
      <scenario-submission-steps-panel
      :gltfSubmitStatus="gltfSubmitStatus"
      @setEditButtonStatus="setEditButtonStatus"
      @saveSimAssetFiles="saveSimAssetFiles"
       />
    </div>
    <div id="geometry-validation-container" v-if="simulation && isSimulationInbound && !isJobTypeImport">
      <automated-geometry-validation />
    </div>
    <b-button @click="enterSceneCompositionMode" class="compose-scene-btn" v-if="showComposeSceneBtn" variant="primary">Compose Scene</b-button>
    <!-- div that Three.js connects to and renders the canvas on -->
    <div v-show="showViewer" :id="`${rank}_viewer_canvas`" ref="viewer" class="no-top-margin-all-descendants"></div>
    <drawing-tool
      v-if="renderDrawingTool"
      @createNewInsight="createNewInsight"
      @saveSceneForNewInsight="saveSceneForNewInsight">
    </drawing-tool>
    <b-modal id="screen-shot-modal" footer-class="d-flex" @ok="cancelScreenshots" centered hide-header-close :hide-footer="cancellingScreenshots" ok-only ok-title="Cancel" ok-variant="danger" no-close-on-backdrop :title="screenshotsModalText" v-model='loadingMultipleScreenshots'>
      <div class="loading-screenshots">
        <b-spinner big variant="primary"></b-spinner>
      </div>
    </b-modal>
    <edit-scenario-modal v-if="openEditModal" @close-modal="openEditModal=false" 
      :showModal="openEditModal" 
      :scenarioName="scenarioNameToEdit" 
      :configurationId="configurationIdToEdit"
      :simulationId="simulationIdToEdit"></edit-scenario-modal>
  </div>
</template>

<script>
import * as OrbitalStackViewer from 'rwdi-viewer3d';
import { EventBus } from '@/network/eventbus';
import Popup from '@/components/views/Popup';
import ReferenceOriginPoint from './ReferenceOriginPoint.vue';
import ColorScale from './ColorScale';
import ViewerToolbar from './ViewerToolbar';
import Windrose from './Windrose';
import ScreenShotControls from './ScreenShotControls.vue';
import { BModal } from 'bootstrap-vue';
import Konami from 'konami';
import { mapGetters, mapActions } from 'vuex';
import ScenarioSubmissionStepsPanel from './ScenarioSubmissionStepsPanel.vue';
import SelectSimulationParametersPanel from './SelectSimulationParametersPanel.vue';
import AutomatedGeometryValidation from './AutomatedGeometryValidation.vue';
import SimulationSettingItem from './SimulationSettingItem.vue';
import DrawingTool from './DrawingTool.vue';
import _ from 'lodash';
import  ImportResultsFile  from './ImportResultsFile';
import { ContainerClient } from '@azure/storage-blob';
import html2canvas from 'html2canvas';
import EditScenarioModal from '@/components/projects/list/EditScenarioModal';
import 'jquery-ui/ui/widgets/draggable';
import $ from 'jquery';

// using a conditional require here because vtk.js can't be imported in the test environment
let vtkXMLPolyDataReader;
if(process.env.NODE_ENV !== 'test') {
  vtkXMLPolyDataReader = require('@kitware/vtk.js/IO/XML/XMLPolyDataReader');
}

export default {
  name: 'Viewer',
  components: {
    Popup,
    BModal,
    ColorScale,
    ReferenceOriginPoint,
    ViewerToolbar,
    Windrose,
    ScenarioSubmissionStepsPanel,
    SelectSimulationParametersPanel,
    ScreenShotControls,
    SimulationSettingItem,
    AutomatedGeometryValidation,
    ImportResultsFile,
    DrawingTool,
    EditScenarioModal
  },
  props: {
    clearAxesHelperIndex: {
      required: false, 
      type: Number
    },
    resultLayersPresent: {
      required: true,
    },
    geometryLayersPresent: {
      required: true,
    },
    insightLayersPresent: {
      required: true,
    },
    showProbeMode: {
      required: false,
      type: Boolean
    },
    simulation: {
      required: true
    },
    selectedSimulationId: {
      required: true
    },
    layersToToggle: {
      required: true
    },
    allWindroses: {},
    rank: {
      type: String,
      required: true
    },
    layerPanelActive: {
      type: Boolean,
      required: true
    },
    layerIsLoading: {
      type: Boolean,
      required: true
    },
    selectedColorTheme: {
      required: true
    },
    probeGroup: {
      required: true
    },
    graphRequiresProbes: {
      type: Boolean,
      required: true
    },
    graphAssets: {
      required: true
    },
    wireframeOn: {
      required: true
    },
    geometryLayerSets: {
      required: false
    },
    simulationLabel: {
      required: false
    },
    removeAllLayers: {
      type: Boolean,
      required: false
    },
  },
  computed: {
    simulationHasResultAssets() {
      if (!this.simulationResultAssets) 
        return false;

      return this.simulationResultAssets.length > 0;
    },
    isForcesAndMoments(){
      let jobType;
      this.simulation?.job_types_with_criteria?.some(x => {
        jobType =  this.jobTypes?.find(z => z.id === x.job_type);
      });
      return jobType?.label.toLowerCase() == 'static structural loading';
    },
    hideProbePointSubmit(){
      if (isNaN(this.customProbePosition.x) ||  isNaN(this.customProbePosition.y) || isNaN(this.customProbePosition.z))
        return true;
      return false;
    },
    resultsRoute() {
      return {
        name: 'ViewerContainer',
        params: {
          id: this.$route.params.id,
          study: this.$route.params.study,
          configuration: this.$route.params.configuration
        }
      };
    },
    showProcessingInProgressDisplay() {
      return this.isViewerMode && (this.waitingForResults || this.waitingForAdditionalResults || this.moreResultsAvailable);
    },
    waitingForResults() {
      return this.simulation?.simulation_type === 'ML' && this.simulationCategory === 'SUBMITTED';
    },
    waitingForAdditionalResults() {  //not to be confused with below, this lag is intended to inform the user that the results they are viewing are not complete.  Post processing of additional results is still underway
      return this.simulation?.moreResultsPending;
    },
    showColorLegendButton() {
      //show the button if there's a color scale available for horizontal and it's not open, or if there's one for volumetric and it's not open
      return (this.horizontalColorScale && !this.colorScaleMenuVisible)  || (this.volumetricColorScale && !this.volColorScaleMenuVisible);
    },
    windroseAvailable() {
      return this.allWindroses?.length;
    },
    currentWindrose() {
      if (this.windroses.length > 0) {
        if(this.windroses[0] && this.windroses[0].asset_file)
          return this.windroses[0];
      }
      return null;
    },
    pinHasPinType() {
      let tagTypeToAdd = this.annotationTypeToCreate.icon ? 'icon' : (isNaN(this.annotationTypeToCreate.tag) ? 'letter' : 'number');
      if(tagTypeToAdd === 'icon') {
        return this.currentInsight.pin_types.find(pinType => pinType.colour === this.annotationTypeToCreate.colour && pinType.icon === this.annotationTypeToCreate.icon);
      } else {
        return this.currentInsight.pin_types.find(pinType => pinType.colour === this.annotationTypeToCreate.colour && pinType.tag_type === tagTypeToAdd);
      }
    },
    showComposeSceneBtn() {
      if (this.savingSceneViewInsight) {
        return false;
      } else if (this.isInsightsMode && this.currentInsightIsSceneView && !this.hasScene && this.editInsightMode && !this.sceneViewCompositionMode)
        return true;
      return false;
    },
    showViewer() {
      if (this.isViewerMode || this.sceneViewCompositionMode)
        return true;
      if (this.isInsightsMode && this.currentInsightIsSceneView && this.hasScene)
        return true;
      return false;
    },
    renderDrawingTool() {
      return this.isViewerMode || (this.isInsightsMode && this.currentInsightIsSceneView);
    },
    activeProbeType() {
      //if both graph and data probes are active we should be in data-probe mode
      if (this.dataProbeStatus) {
        return 'data';
      } else if (this.graphRequiresProbes) {
        return 'graph';
      } else {
        return null;
      }
    },
    simulation_times() {
      let results = [];
      if (this.formVersion == '2.0') {
        results = this.getMetricParameter(['TimeofDay', 'SingleDayHours']);
      } else {
        if ('TimeofDay' in this.simulationSettings) {
          results.push({
            'metric': '',
            'TimeofDay': this.simulationSettings.TimeofDay
          });
        }
      }
      return results.length > 0 ? results : false;
    },
    showReturnPeriods(){
      if (this.formVersion == '2.0') {
        const result = this.getMetricParameter(['returnPeriods']);
        return result.length > 0 ? result : false;
      } else
        return false;
    },
    showStructuralOrigin() {
      if (this.formVersion == '2.0') {
        const results = this.getMetricParameter(['structuralOrigin', 'projectNorthAngle']);
        let metrics = [...new Set(results.map(result => result.metric))];
        let combinedResults = metrics.map(metric => {
          return { metric };
        });
        results.forEach(result => {
          let combinedResult = combinedResults.find(cr => cr.metric === result.metric);
          Object.keys(result).forEach(key => {
            combinedResult[key] = result[key];
          });
        });
        return combinedResults.length > 0 ? combinedResults : false;
      } else
        return false;
    },
    showFrontalAreas() {
      if (this.formVersion == '2.0') {
        const result = this.getMetricParameter(['dragAndLiftAreas', 'xDirAndYDirAreas']);
        return result.length > 0 ? result : false;
      } else
        return false;
    },
    showMinutesInterval(){
      if (this.formVersion == '2.0') {
        const result = this.getMetricParameter(['MinutesInterval']);       
        if(!_.isEmpty(result) && result[0]['MinutesInterval'])
          return result[0]['MinutesInterval'];
        return false;
      } else
        return false;
    },
    showBuildingEnclosure(){
      if (this.formVersion == '2.0') {
        const result = this.getMetricParameter(['buildingEnclosureClassification']);
        return result.length > 0 ? result : false;
      } else
        return false;
    },
    showMetDownload(){
      return !_.isEmpty(this.simulation?.met_source_id) ? this.simulation.met_source_id : false;
    },
    showbuildingRoofHeight(){
      if (this.formVersion == '2.0') {
        const result = this.getMetricParameter(['buildingRoofHeight']);
        return result.length > 0 ? result : false;
      } else
        return false;
    },
    getCompletedDate(){
      if (this.formVersion == '2.0') {
        const result = this.simulation?.sim_date_completed;
        if(result?.length && result.length > 0){
          // format UTC datestring
          const date = result.split('.');
          var dateString = date[0].replace('T', ' ');
          return `${dateString} UTC`;
        }
        return false;
      } else
        return false;
    },
    getSoftwareVersion() {
      if (this.formVersion == '2.0' && this.mlVersionInformation) {
        let software_version_info = {};
        // const pp_image = this.simulation?.container_app_image_name;
        // if(!_.isEmpty(pp_image))
        //   software_version_info.post_processing_version = pp_image;
        
        for (let key in this.mlVersionInformation['ml_api_info']) {
          software_version_info[key] = this.mlVersionInformation['ml_api_info'][key]; 
        }

        if (!_.isEmpty(software_version_info))
          return software_version_info;

        return false;
      } else
        return false;
    },
    showInternalPressure(){
      if (this.formVersion == '2.0') {
        const result = this.getMetricParameter(['internalPressure']);
        return result.length > 0 ? result : false;
      } else
        return false;
    },
    windDirections() {
      return this.simulationSettings.windDirections;
    },
    confidenceIndex () {
      let sorted = [...this.simulationSettings.confidenceIndex];
      return sorted.sort((a, b) => a.wind_direction - b.wind_direction);
    },
    simulationType() {
      return this.simulation?.simulation_type_detailed ? this.simulation.simulation_type_detailed : false;
    },
    met_source() {
      if (this.simulation && this.selectedProject?.met_sources) {
        let source;
        if (this.simulation?.met_source_id) {
          source = this.selectedProject.met_sources.find(x => x.id == this.simulation?.met_source_id);
        } else if (this.selectedProject.met_sources.length == 1) {
          source = this.selectedProject.met_sources[0];
        } else {
          return null;
        }

        if(source?.wind_data_type === 'MEASURED') {
          return { 
            name: source.station.name, 
            met_id: this.simulation?.met_source_id, 
            type: 'MEASURED' 
          };
        } else if (source?.wind_data_type === 'MODELLED') {
          const latlng = {
            lat: `${this.selectedProject.latitude}\u00B0`, lng: `${this.selectedProject.longitude}\u00B0`
          };
          return { 
            met_id: this.simulation?.met_source_id, 
            type: 'MODELLED',
            latlng: latlng
          };
        } else if (source?.wind_data_type === 'CUSTOM') {
          return { 
            name: decodeURI(String(source.custom_met_data.file).split('?')[0].split('/').pop()), 
            met_id: this.simulation?.met_source_id,
            type: 'CUSTOM',
            file_format: source.custom_met_data.file_format
          };
        } else if (source?.wind_data_type.startsWith('FUTURE')) {
          let tokens = source.wind_data_type.split('-');
          return { 
            name: `Scenario ${tokens[1]}, Year ${tokens[2]}`, 
            met_id: this.simulation?.met_source_id,
            type: 'FUTURE' 
          };
        }

      }
      return null;
    },
    analysis_types() {
      let results = [];
      if (this.simulationSettings?.job_types_with_criteria) {
        for (let jt_with_c of this.simulationSettings.job_types_with_criteria) {
          let result = {
            'name': this.jobTypes.find(z => z.id === jt_with_c.job_type)?.label,
            'metrics': []
          };
          if (jt_with_c.criteria) {
            for (let c of jt_with_c.criteria) {
              let matchingcriteria = this.criteria.find(x => x.id === Number(c));
              result.metrics?.push(matchingcriteria?.name);
            }
            results.push(result);
          }
        }
      }
      return results[0] ? results : [];
    },
    contains_cladding() {
      if (this.analysis_types.length > 0) {
        for (let analysis_type of this.analysis_types) {
          if (analysis_type.name?.toLowerCase().includes('cladding')) {
            return true;
          }
        }
      }
      return false;
    },
    activity_level() {
      let results = [];
      if (this.formVersion == '2.0') {
        results = this.getMetricParameter(['activity']);
      } else {
        if ('activity' in this.simulationSettings) {
          results.push({
            'metric': '',
            'activity': this.simulationSettings.activity
          });
        }
      }
      return results[0] ? results : false;
    },
    clothing_profile() {
      let results = [];
      if (this.formVersion == '2.0') {
        const processedMetrics = (arr) => {
          const result = arr.reduce((acc, obj) => {
            if (obj.clothingProfile === 'Custom') 
              return acc;

            const existing = acc.find(item => item.metric === obj.metric);
            if (existing)
              Object.assign(existing, obj);
            else 
              acc.push(obj);

            return acc;
          }, []);
          return result;
        };
        const currentResults = this.getMetricParameter(['customClothingProfile', 'clothingProfileName', 'clothingProfile']);
        const formattedMetrics = processedMetrics(currentResults);
        return formattedMetrics[0] ? formattedMetrics : false;
      } else { 
        results.push({
          'metric': '',
          'clothingProfile': this.simulationSettings.clothingProfile,
        });
        return results;
      }
    },
    scenario_note() {
      if ('scenario_note' in this.simulationSettings) {
        return this.simulationSettings.scenario_note;
      }
      return false;
    },
    seasons() {
      let results = [];
      if (this.formVersion == '2.0') {
        results = this.getMetricParameter(['Seasons', 'SingleDates']);
      } else {
        if ('Seasons' in this.simulationSettings) {
          results.push({
            'metric': '',
            'Seasons': this.simulationSettings.Seasons
          });
          if ('ExtraSeasons' in this.simulationSettings) {
            results[0].Seasons = results[0].Seasons.concat(this.simulationSettings.ExtraSeasons);
          }
        }
      }
      return results[0] ? results : false;
    },
    hasScene() {
      return this.currentInsight?.sceneview_set.length > 0;
    },
    currentInsightIsSceneView() {
      return this.currentInsight?.insight_type == 'Scene';
    },
    isSplitView() {
      return !!this.$route.query.secondary;
    },
    isSimulationInbound() {
      return this.simulation?.category == 'INBOUND';
    },
    uploadUuid() {
      return this.$store.getters['project/uploadUuid'];
    },
    currentCameraPosition() {
      return this.$store.getters['project/viewer/getCameraPosition'];
    },
    currentInsightIndex() {
      return this.$store.getters['project/currentInsightIndex'];
    },
    firstSceneView() {
      return this.$store.getters['project/firstSceneView'];
    },
    camerasWithInsights() {
      return this.$store.getters['project/camerasWithInsights'];
    },
    insightCameraPosition() {
      return this.firstSceneView?.camera_position?.position;
    },
    colorScaleLegendButtonTitle() {
      if (this.colorScales == null) {
        return 'No color scales available';
      }
      return `${this.colorScales.map(scale => scale.Name).join(', ')} ${this.colorScales.length > 1 ? 'scales' : 'scale'} available`;
    },
    windroseButtonTitle() {
      const windroses = this.windroses;
      let list = [];
      if(windroses?.length){
        for (let index = 0; index < windroses.length; index++) {
          const element = windroses[index];
          if(element?.season_filter &&  element?.time_filter)
            list.push(element);
        }
      }
      if(list.length > 0 ){
        return 'wind roses available';
      }else{
        return 'No wind roses available';
      }
    },
    savedCameraPositions() {
      let allSavedCameras = this.$store.getters['project/cameraPositions'];
      allSavedCameras = allSavedCameras?.filter(savedCamera => savedCamera.name !== 'insight-camera');
      if (!allSavedCameras) {
        return [];
      }
      return allSavedCameras;
    },
    showCameraPositionModal() {
      return this.cameraPositionModalVisible.create || this.cameraPositionModalVisible.update;
    },
    themeLabels() {
      return this.$store.getters['project/viewer/themeLabels'];
    },
    easterEggThemeIsNotActive() {
      return this.$store.getters['project/viewer/easterEggThemeIsNotActive'];
    },
    easterEggThemeName() {
      return this.$store.getters['project/viewer/easterEggThemeName'];
    },
    showReviewedByRWDIButton() {
      let isRWDIProject = this.$store.getters['project/showReviewedByRWDIButton'];
      let isCFD = this.simulation?.simulation_type == 'CFD';
      return isRWDIProject && isCFD;
    },
    isPreviewModeOpen() {
      return this.$store.getters['project/isPreviewModeOpen'];
    },
    selectedConfigurationId() {
      return this.$store.getters['project/simulationAsset/configurationId'];
    },
    simulationSettings() {
      let confidenceIndex, setting_parameters;
      if (this.simulation?.confidence_index_list && !_.isEmpty(this.simulation?.confidence_index_list)) {
        confidenceIndex = this.simulation?.confidence_index_list;
      }

      if (this.simulation?.cfd_parameters && !_.isEmpty(this.simulation?.cfd_parameters)) {
        setting_parameters = this.simulation?.cfd_parameters;
      } else{
        if(this.simulation?.ml_parameters && !_.isEmpty(this.simulation?.ml_parameters)){
          setting_parameters = this.simulation?.ml_parameters;
        }
      }

      let job_types_with_criteria = this.simulation?.job_types_with_criteria;
      return { ...setting_parameters, confidenceIndex, job_types_with_criteria };
    },
    userIsSuperuser() {
      return this.loggedInUser?.is_superuser;
    },
    isML() {
      return this.simulation?.simulation_type === 'ML';
    },
    formVersion() {
      return this.simulationSettings?.formVersion;
    },
    isJobTypeImport() {
      let jobType;
      this.simulation?.job_types_with_criteria?.some(x => {
        jobType =  this.jobTypes?.find(z => z.id === x.job_type);
      });
      if(jobType?.label == 'Imports GLTF Results')
        return true;
      return false;
    },
    projectImagePath(){
      return `project/${this.$route.params.id}/`;
    },
    ...mapGetters('project/simulationAsset', ['simulationCategory', 'configurationId', 'geometryAssets', 'userSelectedLayers', 'selectedLayerSets', 'addedLayerSets', 'simulationResultAssets', 'mlVersionInformation']),
    ...mapGetters('project/viewer', ['geometryFilesUploaded', 'geometryChangeTheme', 'windowResizeRequired', 'abortLoadFlag', 'geometryFileRemoved', 'savedLayers',
      'loadingMultipleScreenshots', 'dataProbeStatus', 'mlStatus']),
    ...mapGetters('project', ['isViewerMode', 'isInsightsMode', 'pinTypes', 'currentImage', 'creatingInsightPDF', 'selectedProject', 'insightPinType', 'annotationTypeToCreate', 'createScenarioStep', 'annotationsCompositionMode', 'viewerMode', 'currentInsights', 'currentInsight', 'jobTypes', 'criteria', 'createInsightMode', 'editInsightMode', 'savingSceneViewInsight', 'sceneViewCompositionMode', 'drawingMode','projectUpdatedError']),
    ...mapGetters(['loggedInUser'])
  },
  created() {
    if (this.$route.params.id && this.$route.params.study) {
      this.$store.dispatch('project/getCameraPositions', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study)
      });
    }
    this.$store.dispatch('project/getProjectAssetUploadUrl', Number(this.$route.params.id));
  },
  data() {
    return {
      refPickerInitiated: false, 
      refPickerIndex: 0,
      currentRefPoint: null,
      statusIndex: 0,
      showRefPicker: false,
      currentProbePosition: { x: 0, y: 0, z: 0 },
      simEditButtonStatus: true,
      gltfSubmitStatus: true,
      toolBarProbePoint: false,
      probeStatus: false,
      matchingWindroses: [],
      settingValue: '',
      showSettingDetail: false,
      simToastHeight: null,
      simSettingsInfo: '',
      menuIconVisible: true,
      colorScaleMenuVisible: false,
      volColorScaleMenuVisible: false,
      onlyShowKeyboardControls: false,
      cameraPositionModalVisible: {
        create: false,
        update: false
      },
      windroseModalVisible: false,
      cameraSettingsVisible: false,
      deletingCameraPosition: null,
      colorScales: null,
      horizontalColorScale: null,
      volumetricColorScale: null,
      windroses: [],
      loadedAssets: [],
      currentCameraName: 'Saved camera',
      newCameraName: null,
      cameraPosition: null,
      onSavedCamera: true,
      selectedThemeIndex: 0,
      chosenInboundThemeIndex: null,
      chosenResultThemeIndex: null,
      easterEgg: null,
      reviewedByRWDIInfoModalVisible: false,
      activeCameraView: 'PerspectiveCamera',
      screenShotMode: false,
      canvasWidth: 0,
      canvasHeight: 0,
      aspectX: 0,
      aspectY: 0,
      screenShotSize: '',
      clippingPlaneMode: false,
      clippingPlaneHeight: 0,
      geometryHeight: 0,
      geometryFloor: 0,
      vtpPoints: {},  //dict.  key is asset.item, value is list of points
      vtpPointData: {}, //dict.  key is asset.item, value is list of data for corresponding point
      cancellingScreenshots: false,
      screenshotsModalText: 'Please wait, screenshots in progress...',
      gltfMetaData: null,
      graphProbeCount: 0,
      graphProbeUUIDs: [],
      dataProbeUUIDs: [],
      parametersPanelCreated: false,
      annotationIsBeingPlaced: false,
      drawingToolHasntBeenToggledOff: true,
      cameraPositionHasBeenUpdated: false,
      exportingPDF: false,
      resultLayersList: [], //list of result layers (contains STL geometry files and sim results GLTF files) - scenario submitted and processed
      geometryLayersList: [], //list of geometry files from already submitted scenarios - scenario submitted and processed
      insightLayersList: [], //list of insight layers from insightsTitle.vue - scenario submitted and processed
      openEditModal: false,
      newResultsAvailableText: '',
      moreResultsAvailable: false,
      studyBuildingTransparent: false
    };
  },
  async mounted() {
    EventBus.$on('RESULTS_DELIVERED', this.onResultsDelivered);
    EventBus.$on('ML_STATUS_UPDATE', this.onMLStatusUpdate);
    // event listener: closing the Toast Simulaton Settings menu
    this.$root.$on('takeScreenshot', (filename) => {
      this.saveScreen(false, filename);
    });

    window.addEventListener('resize', this.setCanvasSizeFromAspect);
    this.canvasWidth = this.$refs.viewer.clientWidth;
    this.canvasHeight = this.$refs.viewer.clientHeight;
    this.$store.dispatch('project/viewer/reset');

    let that = this;
    function updateCameraPosition(cameraPosition) {
      that.cameraPosition = cameraPosition;
      that.cameraPositionHasBeenUpdated = true;
    }
    this.viewer = new OrbitalStackViewer.Viewer3D(this.$refs.viewer, {
      compassContainer: this.$refs.compass,
      updateCameraPosition
    });

    this.setClickEvents();


    // Set default colour scheme (async IIFE)
    this.selectedColorTheme.forEach(async colorScheme => await this.viewer.setColorScheme(colorScheme));

    this.easterEgg = new Konami(async () => {
      if (this.easterEggThemeIsNotActive) {
        await this.$store.dispatch('project/viewer/selectEasterEggTheme', true);
        this.selectedThemeIndex = this.themeLabels.indexOf(this.easterEggThemeName);
      }
    });

    if (this.$store.getters['project/isPreviewModeOpen']) {
      this.selectedThemeIndex = this.themeLabels.indexOf('geometry');
    }

    if(this.$route.query?.mode === 'insights')
      this.$store.dispatch('project/setViewerMode', 'Insights');

    $('.draggable-modal').draggable({
      handle: '.modal-header'
    });

    if(!this.simulationHasResultAssets) {
      this.selectedThemeIndex = this.themeLabels.indexOf('geometry');
    } else {
      this.selectedThemeIndex = this.themeLabels.indexOf('default');
    }
  },
  methods: {
    setClickEvents() {
      const delta = 6;
      let startX;
      let startY;

      // set click listener for probe (i.e. click with no drag) only for ML
      this.$refs.viewer.addEventListener('mousedown', (event) => {
        startX = event.pageX;
        startY = event.pageY;
      });

      this.$refs.viewer.addEventListener('mouseup', async e => {
        const diffX = Math.abs(e.pageX - startX);
        const diffY = Math.abs(e.pageY - startY);
        let mouse_clicked = false;
        if (diffX < delta && diffY < delta) {
          mouse_clicked = true;
        } //else mouse dragged, not clicked

        let clicked_layer_uuid, clickedPoint;
        if(mouse_clicked) {
          const clicked_result = this.getClickedCoordinates(e);
          if (clicked_result) {
            clicked_layer_uuid = clicked_result.uuid;
            clickedPoint = clicked_result.point;
          }
        }
        if(mouse_clicked && this.showRefPicker){
          let xNdc = (e.offsetX / this.$refs.viewer.clientWidth) * 2 - 1;
          let yNdc = -(e.offsetY / this.$refs.viewer.clientHeight) * 2 + 1;
          let clickedGeometry = this.viewer.findNearestIntersectingPointInVisibleMeshes(xNdc, yNdc, ['geometry.ground']);
          if(clickedGeometry?.point){
            this.setAxesHelper(clickedGeometry.point);
            this.currentRefPoint = clickedGeometry.point;
          }
        }
        if(mouse_clicked && clickedPoint){
          //annotation composition
          if (this.annotationsCompositionMode && !this.annotationIsBeingPlaced) {
            this.annotationIsBeingPlaced = true;
            let uuid = this.viewer.drawPinAtPoint(clickedPoint[0], clickedPoint[1], clickedPoint[2], this.annotationTypeToCreate.tag, this.annotationTypeToCreate.colour, this.annotationTypeToCreate.icon);
            this.viewer.renderPins();
            let insightPinDetails = {
              x_coord: clickedPoint[0],
              y_coord: clickedPoint[1],
              z_coord: clickedPoint[2],
              tag: this.annotationTypeToCreate.tag,
              sort_order: 1,
              insight_id: this.currentInsight.id,
              colour: this.annotationTypeToCreate.colour,
              icon: this.annotationTypeToCreate.icon
            };

            let insightPinTypeDetails = {
              project_id: Number(this.$route.params.id),
              insight_id: this.currentInsight.id,
              colour: this.annotationTypeToCreate.colour,
              icon: this.annotationTypeToCreate.icon,
              name: this.annotationTypeToCreate.name,
              description: this.annotationTypeToCreate.description,
              type: this.annotationTypeToCreate.type,
              tag_type: this.annotationTypeToCreate.tagType.toLowerCase()
            };
            let tagTypeToAdd = this.annotationTypeToCreate.icon ? 'icon' : (isNaN(this.annotationTypeToCreate.tag) ? 'letter' : 'number');
            let tagToAdd = null;
            if (tagTypeToAdd === 'letter')
              tagToAdd = String.fromCharCode(this.annotationTypeToCreate.tag.charCodeAt(0) + 1);
            else if (tagTypeToAdd === 'number')
              tagToAdd = String(Number(this.annotationTypeToCreate.tag) + 1);

            this.setAnnotationTypeToCreate({
              name: this.annotationTypeToCreate.name,
              description: this.annotationTypeToCreate.description,
              type: this.annotationTypeToCreate.type,
              tag: tagToAdd,
              icon: this.annotationTypeToCreate.icon,
              colour: this.annotationTypeToCreate.colour,
              tagType: this.annotationTypeToCreate.tagType
            });

            // If the selected pin doesn't have a pin type in the layerspanel, create a pin type and add it to the layerspanel
            // with the pin
            if (this.pinHasPinType) {
              // Find pin type of selected pin to add based on the colour and icon/tag
              let insightPinTypeFound = null;
              if (tagTypeToAdd === 'icon')
                insightPinTypeFound = this.currentInsight.pin_types.find(pinType => pinType.colour === this.annotationTypeToCreate.colour && pinType.icon === this.annotationTypeToCreate.icon);
              else
                insightPinTypeFound = this.currentInsight.pin_types.find(pinType => pinType.colour === this.annotationTypeToCreate.colour && pinType.tag_type === tagTypeToAdd);

              insightPinDetails.insight_pin_type_id = insightPinTypeFound.id;

              // Create pin
              await this.$store.dispatch('project/addInsightPin', {
                projectId: Number(this.$route.params.id),
                studyId: Number(this.$route.params.study),
                insightId: this.currentInsight.id,
                insightPinDetails: insightPinDetails,
                uuid: uuid
              });
            } else {
              // Create pin type
              await this.$store.dispatch('project/addInsightPinType', {
                projectId: Number(this.$route.params.id),
                studyId: Number(this.$route.params.study),
                insightId: this.currentInsight.id,
                insightPinTypeDetails: insightPinTypeDetails
              });

              insightPinDetails.insight_pin_type_id = this.insightPinType.id;

              // Create pin
              await this.$store.dispatch('project/addInsightPin', {
                projectId: Number(this.$route.params.id),
                studyId: Number(this.$route.params.study),
                insightId: this.currentInsight.id,
                insightPinDetails: insightPinDetails,
                uuid: uuid
              });
            }

            //add pin to layerspanel
            this.$store.dispatch('project/setNewAnnotationAdded', true);
            this.annotationIsBeingPlaced = false;

          }

          //data probes
          if(this.activeProbeType == 'data') {
            let probePointID = this.getDataPointForClick(clicked_layer_uuid, ...clickedPoint);
            if (probePointID != null) {
              probePointID = probePointID.toFixed(2);
              const icon ='geo';
              let dataProbeUUID = this.viewer.drawPinAtPoint(clickedPoint[0], clickedPoint[1], clickedPoint[2], probePointID, 'rgb(255, 255, 255)', icon, probePointID, true);
              this.viewer.renderPins();
              this.dataProbeUUIDs.push(dataProbeUUID);
              await this.$nextTick();
              this.viewer.render();
            }
          }

          //graph probes
          if(this.activeProbeType == 'graph') {
            this.graphProbeCount += 1;

            let probeLabel = this.graphProbeCount.toString();
            let probeUUID = this.viewer.drawPinAtPoint(clickedPoint[0], clickedPoint[1], clickedPoint[2], probeLabel, 'rgb(0, 25, 255)');  //color here should match color of labels in graph-panel
            this.viewer.renderPins();
            this.graphProbeUUIDs.push(probeUUID);

            let probe = {
              label: probeLabel,
              point: clickedPoint,
              uuid: probeUUID
            };

            this.$emit('graphProbePlaced', probe);

          }
        }
      });
    },
    setRefPicker(status){
      this.showRefPicker = status;
    },
    showSimParams(){
      this.showRefPicker = false;
      this.statusIndex += 1;
    },
    toggleRefPicker(){
      this.showRefPicker = !this.showRefPicker;
    },
    removeAxesHelper(){
      this.viewer.clearAxesHelper();
    },
    rotateEulerAngles(probePosition) {
      return {
        x: Number(probePosition.x * -1),
        z: Number(probePosition.y),
        y: Number(probePosition.z)
      };
    },
    setAxesHelper(customProbePosition){
      this.viewer.setAxesHelper(customProbePosition.x, customProbePosition.y, customProbePosition.z); 
    },
    goToResults() {
      this.$router.push(this.resultsRoute);
      this.$router.go(0);
    },
    onMLStatusUpdate(args) {
      if (args.scenario_id.toString() === this.configurationId.toString()) {
        this.setMLStatus(args.status_message);
      }
    },
    onResultsDelivered(args) {
      if (args.scenario_id.toString() === this.configurationId.toString()) {
        if (_.isEmpty(this.simulationResultAssets)) {
          this.newResultsAvailableText = 'Simulation Complete';
        } else {
          this.newResultsAvailableText = 'More results are available';
        }
        this.moreResultsAvailable = true;
      }
    },
    openColorScales() {
      if (this.horizontalColorScale && !this.colorScaleMenuVisible) {
        this.colorScaleMenuVisible = true;
      }
      
      if (this.volumetricColorScale && !this.volColorScaleMenuVisible) {
        this.volColorScaleMenuVisible = true;
      }
    },
    async captureScreenshot() {
      if (this.currentInsight.insight_type == 'Scene') {
        let canvas = document.createElement('canvas');
        let canvasContext = canvas.getContext('2d');
        let viewerCanvas = this.viewer.renderer.domElement;
        let fabricCanvas = document.getElementById('fabric_canvas');
        
        if (viewerCanvas) {
          const viewerImage = new Image();
          viewerImage.src = viewerCanvas.toDataURL('image/png');
          await viewerImage.decode();
          const drawingImage = new Image(viewerImage.width, viewerImage.height);
          drawingImage.src = fabricCanvas.toDataURL('image/png');
          await drawingImage.decode();
          canvas.width = viewerImage.width;
          canvas.height = viewerImage.height;
          canvasContext.drawImage(viewerImage, 0, 0);
          canvasContext.drawImage(drawingImage, 0, 0, viewerImage.width, viewerImage.height);
          const imageSrc = canvas.toDataURL('image/png');
          return imageSrc;
        }
      } else {
        try {
          const base64Image = await this.loadImageAsBase64(this.currentImage.image);
          return base64Image;
        } catch (error) {
          console.error('Failed to load the image:', error);
        }
      }

      return null;
    },
    loadImageAsBase64(url) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let currentImage = document.getElementById('current_image');
          let imageWidth = currentImage.width;
          let imageHeight = currentImage.height;

          if(this.drawingMode) {
            let viewerContainer = document.getElementById('fabric_canvas_container');
            let viewerWidth = viewerContainer.clientWidth;
            let viewerHeight = viewerContainer.clientHeight;

            let fabricCanvas = document.getElementById('fabric_canvas');
            const drawingImage = new Image(viewerWidth, viewerHeight);
            drawingImage.src = fabricCanvas.toDataURL('image/png');
            await drawingImage.decode();

            canvas.width = viewerWidth;
            canvas.height = viewerHeight;

            let translationFactor = (viewerWidth - imageWidth) / 2;
            let borderThickness = 5;

            ctx.drawImage(image, translationFactor, 0, imageWidth, imageHeight);
            ctx.drawImage(drawingImage, 0, borderThickness, viewerWidth, viewerHeight);
          } else {
            let imageContainer = document.getElementById('image_container');

            canvas.width = imageContainer.clientWidth;
            canvas.height = imageContainer.clientHeight;

            let translationFactor = (imageContainer.clientWidth - imageWidth) / 2;

            ctx.drawImage(image, translationFactor, 0, imageWidth, imageHeight);
          }
          const imageSrc = canvas.toDataURL('image/png');
          resolve(imageSrc); // Resolve the promise with the base64 image data
        };
        image.onerror = (error) => {
          reject(error); // Reject the promise if there's an error
        };
        image.src = url;
      });
    },
    async captureAllScreenshots() {
      // jump to first insight
      this.$router.push({ 
        path: 'insights',
        params: { tabName: this.selectedViewerMode, },
        query: { page: 0 }});
      // await this.waitForSceneToLoad(5000);
      this.setShowCreatingInsightPDFLoadingScreen(true);
      this.exportingPDF = true;
      let screenshots = [];
      let titles = [];
      let descriptions = [];
      let pinTypesHtmlList = [];
      // let pinTypesData = [];
      for (let i = 0; i < this.currentInsights.length; i++) {
        // Programmatically navigate to the next insight
        if (this.currentInsights[i].insight_type == 'Scene' || this.currentInsights[i].insight_type == 'Image') {
          this.$router.push({ 
            path: 'insights',
            params: {
              tabName: this.selectedViewerMode,
            },
            query: {
              page: i
            }
          });
          
          // Wait for the 3D scene to load
          await this.waitForSceneToLoad(5000); // Implement this based on your loading logic
          // Capture screenshot
          const screenshot = await this.captureScreenshot();
          if(screenshot) {
            screenshots.push(screenshot);
            titles.push(this.currentInsights[i].title);
            descriptions.push(this.currentInsights[i].description);
            let pinTypesHtml = this.$store.getters['project/pinTypes'];
            let temp = [];
            let posOfPinType = 0;
            if(pinTypesHtml) {
              for (const element of pinTypesHtml) {
                try {
                  const canvas = await html2canvas(element);
                  const base64Image = canvas.toDataURL('image/png');
                  let currPinType = this.currentInsights[i].pin_types[posOfPinType];
                  temp.push(
                    {
                      title: currPinType.name,
                      desc: currPinType.description,
                      image: base64Image,
                      pins: this.currentInsights[i].pins.filter(pin => pin.insight_pin_type_id == currPinType.id)
                    }
                  );

                  posOfPinType++;
                } catch (error) {
                  console.error('Error capturing and encoding:', error);
                }
              }
            }
            pinTypesHtmlList.push(temp);
          }
        }
      }
      // Do something with the screenshots, e.g., send them to the backend
      this.uploadInsights(screenshots, titles, descriptions, pinTypesHtmlList);
      this.exportingPDF = false;
      this.setShowCreatingInsightPDFLoadingScreen(false);
    },
    waitForSceneToLoad(delay) {
      return new Promise(resolve => setTimeout(resolve, delay));
    },
    uploadInsights(imgsData, titlesData, descsData, pinTypesData) {
      const payload = {
        images: imgsData,
        titles: titlesData,
        descriptions: descsData,
        pin_types: pinTypesData
      };

      this.$store.dispatch('project/exportPDF', {
        pdfDetails: payload
      });
    },
    setEditButtonStatus(status){
      this.simEditButtonStatus = status;
    },
    async createResultSim(){
      let payload = {
        configurationId: this.selectedConfigurationId,
        simulationType: this.simulationType,
        assets: [],
        manifest: {},
        isImportResult: 1
      };
      let assets = [];
      let manifests = {};
      if (this.gltfMetaData) {
        for(let file of this.gltfMetaData){
          if(file.manifest && file.path){
            assets.push(file.path);
            const name = file.name;
            manifests[name] = file.manifest;
          }
        }
      }
      payload.manifest = manifests;
      payload.assets = assets;
      try {
        this.setScenarioSubmissionInProgress(true);
        await this.storeImportResults({
          gltfMetaData: payload,
        });
        this.setScenarioSubmissionInProgress(false);
      } catch (error) {
        console.error(error);
        this.setScenarioSubmissionInProgress(false);
        return false;
      }
      location.reload();
    },
    async saveSimAssetFiles(){
      if(this.selectedProject.is_demo_project) return null;
      await this.createResultSim();
    },
    setSubmitStatus(status){
      this.gltfSubmitStatus = status;
    },
    setmetaData(payload){
      this.gltfMetaData = payload;
    },
    closeDetailModal(){
      this.showGLTFModal = false;
    },
    setProbeStatus(probeStatus) {
      this.probeStatus = probeStatus;
    },
    closeAnnotationComposition() {
      this.$store.dispatch('project/setAnnotationsCompositionMode', false);
    },
    async createNewInsight(done) {
      this.$emit('createNewInsight', () => {
        done();
      });
    },
    simulationSettingsClosed(){
      this.menuIconVisible = true;
      this.showSettingDetail = false;
      this.simSettingsInfo = '';
    },
    getMetricParameter(parameterNameList) {
      let metric_list = [];
      for (let property in this.simulationSettings) {
        if (property !== 'formVersion' && property!== 'confidenceIndex' && property != 'windDirections' && property != 'scenario_note')
          metric_list.push(property);
      }

      let results = [];
      for (let metric of metric_list) {
        for (let parameterName of parameterNameList) {
          if (parameterName in this.simulationSettings[metric][0]) {
            let result = {
              'metric': metric
            };
            result[parameterName] = this.simulationSettings[metric][0][parameterName];
            results.push(result);
          }
        }
      }
      return results;
    },
    async clearDataProbes() {
      for (let probe_uuid of this.dataProbeUUIDs) {
        await this.viewer.remove({group: null, id: probe_uuid});
      }
      this.dataProbeUUIDs.length = 0;
    },
    async clearGraphProbes() {
      this.graphProbeCount = 0;  //reset counter when deactivated

      //clear probe points from the visualizer
      for (let probe_uuid of this.graphProbeUUIDs) {
        await this.viewer.remove({group: null, id: probe_uuid});
      }

      this.graphProbeUUIDs.length=0;
    },
    async removeProbe(uuid) {
      //this.graphProbeCount-=1;
      //don't decrease the probe count as this is used to generate a label for the probes.
      //If we have probes 1, 2, 3 and we remove 2, we want the next probe to still be labelled 4, not 3,
      //otherwise we'll have 2 probes labelled 3
      await this.viewer.remove({group: null, id: uuid});
      this.graphProbeUUIDs.splice(this.graphProbeUUIDs.indexOf(uuid), 1);
    },
    async removeAnnotationPin(annotation) {
      await this.viewer.remove({group: null, id: annotation.item});
    },
    getClickedCoordinates(e) {
      // calculate normalized device coordinates of click (x, y)
      let xNdc = (e.offsetX / this.$refs.viewer.clientWidth) * 2 - 1;
      let yNdc = -(e.offsetY / this.$refs.viewer.clientHeight) * 2 + 1;

      // find 3D point clicked and save. If no point then return.
      let clickedGeometry;
      if(this.annotationsCompositionMode) {
        clickedGeometry = this.viewer.findNearestIntersectingPointInVisibleMeshes(xNdc, yNdc);
      } else {
        let groupsToProbe = this.probeGroup ? [this.probeGroup, 'Horizontal'] : ['Horizontal'];
        clickedGeometry = this.viewer.findNearestIntersectingPointInVisibleMeshes(xNdc, yNdc, groupsToProbe);
      }
      if (!clickedGeometry) return null;
      //find the parent of type "Group" and get it's uuid
      let uuid;
      let finished = false;
      let obj = clickedGeometry.object;
      while(!finished) {
        let type = obj.type;
        if (type == 'Group') {
          uuid = obj.uuid;
          finished = true;
        }
        obj = obj.parent;
      }

      return {uuid: uuid, point: [clickedGeometry.point.x, clickedGeometry.point.y, clickedGeometry.point.z]};
    },
    getDataPointForClick(uuidClicked, xClicked, yClicked, zClicked) {
      // only probe vtp if vtp file data has been cached
      if(this.vtpPoints[uuidClicked].length > 0 && this.vtpPointData[uuidClicked].length > 0) {
        // find closest point to clicked point in points array then get the point data for that point
        let px, py, pz, distance; // point x,y,z + temp distance
        let pIdx = 0; // point index
        let dIdx; // data index
        let minDistance = Number.MAX_SAFE_INTEGER; // minimum distance
        for(let i = 0; i < this.vtpPoints[uuidClicked].length; i += 3) {
          //coordinates from the viewer are y-up, vtp and json files from post processor are z-up so we need ot transform the coordinates before probing into the data
          px = -this.vtpPoints[uuidClicked][i];   //x becomes -x
          py = this.vtpPoints[uuidClicked][i+2];  //z becomes y
          pz = this.vtpPoints[uuidClicked][i+1];  //y becomes z

          distance = Math.sqrt((px - xClicked)**2 + (py - yClicked)**2 + (pz - zClicked)**2);
          if(distance < minDistance) {
            minDistance = distance;
            dIdx = pIdx;
          }

          pIdx++;
        }

        // only return data points that are less than 1.5m away from clicked point
        if(minDistance < 1.5) {
          return this.vtpPointData[uuidClicked][dIdx];
        }
        return null;
      }
    },
    closeKeyboard(){
      this.menuIconVisible = true;
      this.showSettingDetail = false;
    },
    selectDetail: function(paramType, settingValue=null){
      this.simToastHeight = Number(this.$refs.simToastBody?.clientHeight) + Number(this.$refs.simToastHeader?.clientHeight);
      this.showSettingDetail = true;
      this.simSettingsInfo = paramType;
      this.settingValue = settingValue;
    },
    showToast(){
      this.menuIconVisible = false;
      if(!this.isSimulationInbound){
        this.onlyShowKeyboardControls = false;
        this.$bvToast.show(`${this.rank}-sim-settings-menu`);
      }else{
        this.showSettingDetail = true;
        this.onlyShowKeyboardControls = true;
        this.simSettingsInfo = 'keyboard';
      }
    },
    setLayerIsLoading(isLoading) {
      this.$emit('setLayerIsLoading', isLoading);
    },
    takeMultipleScreenshots(status) {
      this.$emit('takeMultipleScreenshots', status);
    },
    async resetCamera() {
      await this.viewer.resetCamera();
    },
    async orientCameraNorth() {
      await this.viewer.orientCameraNorth();
    },
    abortLoad() {
      this.viewer?.abortLoad();
    },
    async submittedFormValues(loadedGeometryAssets) {
      if (this.simulation) {
        const asset_file_string = `configurations/${this.selectedConfigurationId}/simulation/${this.simulation.id}`;
        const geometry_assets = loadedGeometryAssets?.filter(asset => asset.simulationResult.asset_file.includes(asset_file_string));

        let newFormValues;
        if (geometry_assets == null || geometry_assets.length == 0) {
          newFormValues = {
            'scenario_name': this.simulation.configuration.name,
            'scenario_note': this.simulation.configuration.note,
            'simulation_type': this.simulation.simulation_type,
            'subscription_type': this.simulation.subscription_type,
            'job_types_with_criteria': this.simulation.job_types_with_criteria
          };
        } else {
          newFormValues = {
            'scenario_name': this.simulation.configuration.name,
            'scenario_note': this.simulation.configuration.note,
            'simulation_type': this.simulation.simulation_type,
            'subscription_type': this.simulation.subscription_type,
            'job_types_with_criteria': this.simulation.job_types_with_criteria,
            'study-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('study'))
              .map(asset => this.createFormFiles(asset.simulationResult))
            ,
            'surrounds-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('surround'))
              .map(asset => this.createFormFiles(asset.simulationResult))
            ,
            'ground-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('ground'))
              .map(asset => this.createFormFiles(asset.simulationResult))
            ,
            'presentation_plane-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('presentation'))
              .map(asset => this.createFormFiles(asset.simulationResult))
            ,
            'landscaping-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('landscaping'))
              .map(asset => this.createFormFiles(asset.simulationResult))
            ,
            'mitigations-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('mitigation'))
              .map(asset => this.createFormFiles(asset.simulationResult))
            ,
            'overlay-file': geometry_assets.filter(asset => asset.simulationResult.geometry_type.includes('overlay'))
              .map(asset => this.createFormFiles(asset.simulationResult))
          };
        }
        await this.$store.dispatch('project/setSubmittedFormValues', { newFormValues });
      }
    },
    createFormFiles(asset) {
      return {
        asset_id: asset.id,
        file: asset.filename,
        name: asset.filename,
        path: `${this.uploadUuid}/${asset.id}-${asset.filename}`,
        url: asset.asset_file
      };
    },
    async loadSimulationResult(simulationResult) {
      try {
        return await this.viewerLoadAsset(simulationResult);
      } catch (e) {
        if (e.type !== 'abort') {
          let reloadedSimulationResult = await this.reloadAsset(simulationResult);
          simulationResult.asset_file = reloadedSimulationResult.asset_file;
          return await this.viewerLoadAsset(simulationResult);
        }
        return null;
      }
    },
    async viewerLoadAsset(asset) {
      const obj3d = await this.viewer.load({
        url: asset.asset_file,
        filename: asset.filename,
      });
      return obj3d;
    },
    async viewerRenderAsset(asset, obj3d) {
      // Load the asset, with order of precedence for group name (affects material)
      let asset_group = this.generateGroupFromAsset(asset);
      const renderedAssetInformation = await this.viewer.renderAsset({
        obj3d: obj3d,
        filename: asset.filename,
        group: asset_group,
      });
      return renderedAssetInformation;
    },
    async reloadAsset(asset) {
      const newAsset = await this.$store.dispatch('project/simulationAsset/getSimulationAsset', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        assetId: asset.id
      });
      return newAsset;
    },
    showUnauthorizedAssetError() {
      this.$store.dispatch('project/setError', new Error('The asset you\'re trying to load is unavailable right now. Please contact your administrator for more help'));
    },
    generateGroupFromAsset(asset) {
      if (asset.layer_type?.toLowerCase() !== 'geometry') {
        return asset.layer_type;
      }

      let geometry_type = asset.geometry_type || asset.surface_type;
      if (geometry_type === 'surrounds')
        geometry_type = 'surround';

      const groupLabels = [
        asset.layer_type.toLowerCase(),
        geometry_type,
        asset.geometry_generation
      ].filter(type => type);
      return this.checkForMatchingGroupWithinSelectedTheme(groupLabels);
    },
    checkForMatchingGroupWithinSelectedTheme(groupLabels) {
      const group = groupLabels.join('.');
      if (this.selectedColorTheme.some(theme => theme.group === group)) {
        return group;
      } else if (groupLabels.length > 0) {
        groupLabels.pop();
        return this.checkForMatchingGroupWithinSelectedTheme(groupLabels);
      }
      return 'default'; // Can't find group within selected theme. Sets the group to use the default palette/group
    },
    async setCameraView(name) {
      await this.viewer.setActiveCamera(name);
      this.activeCameraView = name;
      await this.viewer.resizeWindow(this.$refs.viewer);
    },
    async saveCameraPosition() {
      this.onSavedCamera = true;

      const currentCameraPosition = {
        position: this.viewer.getCamera(),
        name: this.currentCameraName,
        study: Number(this.$route.params.study)
      };

      await this.$store.dispatch('project/addCameraPosition', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        cameraPosition: currentCameraPosition,
      });

      this.cameraPositionModalVisible.create = false;
      this.cameraSettingsVisible = true;
    },
    saveSceneForNewInsight(done) {
      let details = this.getCurrentSceneViewDetails();
      this.$emit('saveSceneForNewInsight', { details, done: () => {
        done();
      }});
    },
    getCurrentSceneViewDetails() {
      // Save Camera Position
      const currentCameraPosition = {
        position: this.viewer.getCamera(),
        name: 'insight-camera',
        study: Number(this.$route.params.study)
      };

      // Get list of turned on layers
      const activeLayers = [];
      for (let layer of this.addedLayerSets) {
        if (layer.isVisible) {
          for (let layerSetAsset of layer.layerSetAssets) {
            if (layerSetAsset.isVisible) {
              activeLayers.push({simulationasset_id: layerSetAsset.simulationResult.id});
            }
          }
        }
      }

      // Save Sceneview
      const currentSceneViewDetails = {
        configuration: this.selectedConfigurationId,
        active_layers: activeLayers,
        camera_position: currentCameraPosition,
        title: this.currentInsights[this.currentInsightIndex].name,
        theme: this.themeLabels[this.selectedThemeIndex],
        insight: this.currentInsight.id,
        drawings: [],
        windrose_on: this.windroseModalVisible == true ? true : false,  //might be undefined, need to force to bool
        legend_on: this.colorScaleMenuVisible == true ? true : false
      };

      return currentSceneViewDetails;
    },
    async addInsightCamera() {
      let currentSceneViewDetails = this.getCurrentSceneViewDetails();
      this.$emit('saveSceneView', currentSceneViewDetails);
    },

    showCameraPosition() {
      const currentCameraView = this.viewer.getCamera().object.type;
      const newCameraView = this.currentCameraPosition.position.object.type;
      if (newCameraView !== currentCameraView) {
        this.setCameraView(newCameraView);
      }
      this.viewer.setCamera({
        position: this.currentCameraPosition.position.position,
        target: this.currentCameraPosition.position.target,
        zoom: this.currentCameraPosition.position.object.zoom,
        duration: 2500
      });
      this.cameraPositionModalVisible.create = false;
    },
    async updateCameraPosition() {
      try {
        await this.$store.dispatch('project/updateCameraPosition', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          id: this.currentCameraPosition.id,
          updatedCameraPosition: {name: this.newCameraName},
        });
        this.currentCameraPosition.name = this.newCameraName;
      } catch (error) {
        return;
      }

      this.cameraPositionModalVisible.update = false;
    },
    async deleteCameraPosition() {
      this.deletingCameraPosition = this.currentCameraPosition.id;
      try {
        await this.$store.dispatch('project/deleteCameraPosition', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          cameraPositionId: this.currentCameraPosition.id,
        });
      } catch (error) {
        return;
      }
      this.deletingCameraPosition = null;
    },
    async toggleScreenShotMode() {
      this.screenShotMode = !this.screenShotMode;
      this.$refs.viewer.style.width = '100%';
      this.$refs.viewer.style.height = 'calc(100% - 62px)';

      await this.viewer.resizeWindow(this.$refs.viewer);

      //reset the position of the draggable screenshot controls window
      var screenshot_controls = document.getElementById('screenshot-controls-container');
      screenshot_controls.style.left = 0;
      screenshot_controls.style.top = 0;

    },
    async setCanvasWidth(width) {
      this.$refs.viewer.style.width = `${width}px`;
      await this.viewer.resizeWindow(this.$refs.viewer);
      this.canvasWidth = parseInt(width);
    },
    async setCanvasHeight(height) {
      this.$refs.viewer.style.height = `${height}px`;
      await this.viewer.resizeWindow(this.$refs.viewer);
      this.canvasHeight = parseInt(height);
    },
    setAspectX(x) {
      this.aspectX = parseInt(x);
      if(this.aspectY)
        this.setCanvasSizeFromAspect();
    },
    setAspectY(y) {
      this.aspectY = parseInt(y);
      if(this.aspectX)
        this.setCanvasSizeFromAspect();
    },
    setCanvasSizeFromAspect() {
      if(this.screenShotMode) {
        this.$refs.viewer.style.width = '100%';
        this.$refs.viewer.style.height = 'calc(100% - 62px)';
        let width = this.$refs.viewer.clientWidth;
        let height = this.$refs.viewer.clientHeight;
        let widthBasedHeight = (width / this.aspectX) * this.aspectY;
        if(widthBasedHeight <= height) {
          this.setCanvasWidth(width);
          this.setCanvasHeight(widthBasedHeight);
        } else {
          let heightBasedWidth = (height / this.aspectY) * this.aspectX;
          this.setCanvasWidth(heightBasedWidth);
          this.setCanvasHeight(height);
        }
      }
    },
    async saveScreen(saveScreen, layername) {

      const widthsBySize = {
        small: 800,
        medium: 1600,
        large: 4000
      };
      let width = widthsBySize[this.screenShotSize];
      let height = (width / this.aspectX) * this.aspectY;
      this.setCanvasWidth(width);
      this.setCanvasHeight(height);
      let filename = '';
      if (layername) {
        filename = `${this.simulation.configuration.name}__${layername}`;
      }else{
        filename = this.simulation.configuration.name;
      }
      setTimeout(() => {
        this.viewer.saveScreen(filename);
        this.setCanvasSizeFromAspect();
        if(saveScreen) this.cameraPositionModalVisible.create = true;
      }, 20);
    },
    async updateProjectImage(filename) {
      const id = Number(this.$route.params.id);
      const updatedParameters = {
        scenario_screenshot: true,
        project_image: `${this.projectImagePath}${filename}`
      };
      await this.$store.dispatch('project/updateProject', {id, updatedParameters});
      if (this.projectUpdatedError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to update project image'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'New project image saved'
        });
      }    
    },
    async setProjectImage(saveScreenAngle) {
      const widthsBySize = {
        small: 800,
        medium: 1600,
        large: 4000
      };
      let width = widthsBySize[this.screenShotSize];
      let height = (width / this.aspectX) * this.aspectY;
      this.setCanvasWidth(width);
      this.setCanvasHeight(height);
      let filename = `${this.simulation.configuration.name}_img.jpg`;
      let imageBlob = null;
      setTimeout(() => {
        imageBlob = this.viewer.getProjectImage();
        let blobpre = imageBlob.replace('data:', '').replace(/^.+,/, '');
        let bufferImage = Buffer.from(blobpre, 'base64');
       
        let containerClient = new ContainerClient(`${this.$store.getters['project/projectAssetsContainerUrl']}${this.projectImagePath}?${this.$store.getters['project/projectAssetsSasToken']}`);
        let blobBlockClient = containerClient.getBlockBlobClient(filename);
        blobBlockClient.uploadData(bufferImage, { blobHTTPHeaders: { blobContentType: 'image/jpeg' } }).then(
          () => {
            this.updateProjectImage(filename);
            if (saveScreenAngle) this.cameraPositionModalVisible.create = true;
          },
          (error) => {
            console.error(error);
          }
        );
        this.setCanvasSizeFromAspect();
      }, 20);
    },
    setScreenShotSize(size) {
      this.screenShotSize = size;
    },
    async toggleClippingPlaneMode() {
      this.clippingPlaneMode = !this.clippingPlaneMode;
      await this.viewer.enableClippingPlanes(this.clippingPlaneMode);
    },
    async setClippingPlaneHeight(height) {
      this.clippingPlaneHeight = parseFloat(height);
      await this.viewer.setClippingPlaneHeight(height);
    },
    async updateColorLegendAndWindrose(asset) {
      if (asset?.simulationResult?.layer_type.toLowerCase() !== 'geometry') {
        this.windroses = this.findMatchingWindroses(asset);
        const colorLegendParameters = {
          colorLegendName: asset?.simulationResult?.color_legend_name,
          colorLegendVersion: asset?.simulationResult?.color_legend_version || 1
        };
        if (asset?.simulationResult?.color_legend_name){
          let allLegends = this.$store.getters['project/simulationAsset/colorLegend'];
          if (!allLegends[`"${colorLegendParameters.colorLegendName}-${colorLegendParameters.colorLegendVersion}"`]) {
            await this.$store.dispatch('project/simulationAsset/getColorLegendByName', colorLegendParameters);
            allLegends = this.$store.getters['project/simulationAsset/colorLegend'];
          }
          let asset_legends = allLegends[`"${colorLegendParameters.colorLegendName}-${colorLegendParameters.colorLegendVersion}"`];

          for (let legend of asset_legends) {
            //override the legends default plot description if one is provided in the manifest but doens't exist in the DB legend
            if (asset?.simulationResult?.color_legend_plot_description && !legend.PlotDescription) {
              legend.PlotDescription = asset?.simulationResult?.color_legend_plot_description;
            }

            //if a plot subtitle is defined in the manifest, replace the placeholder text with values from this asset and set it on the color legend subtitle
            legend.PlotSubtitle = this.buildLegendSubtitle(asset?.simulationResult?.color_legend_subtitle, asset?.simulationResult);

          }

          this.colorScales = asset_legends;

          if (asset.simulationResult.layer_type == 'Volumetric') {
            this.volumetricColorScale = asset_legends;
          } else {
            this.horizontalColorScale = asset_legends;
          }
        } else {
          // Bit of a hack to get color scales working for different layer types. Ideally we would have this encoded in the asset so we don't have to worry about mapping this out.
          this.colorScales = asset?.simulationResult?.color_map?.map(color_map => { return { layer_type: asset.simulationResult.layer_type, color_map }; });
          
          if (asset.simulationResult.layer_type == 'Volumetric') {
            this.volumetricColorScale = asset?.simulationResult?.color_map?.map(color_map => { return { layer_type: 'Volumetric', color_map }; });
          } else {
            this.horizontalColorScale = asset?.simulationResult?.color_map?.map(color_map => { return { layer_type: 'Horizontal', color_map }; });
          }
        }
      }
    },
    buildLegendSubtitle(subtitleTemplate, asset) {
      let subtitle = '';
      if (subtitleTemplate) {

        //replace wind direction placeholder
        let direction = asset?.wind_direction;
        if (direction) {
          subtitleTemplate = subtitleTemplate.replace('{direction}', `${direction} •`);
        } else {
          subtitleTemplate = subtitleTemplate.replace('{direction}', '');
        }

        //replace {season} placeholder
        let season_name = asset?.season_range?.name;
        if (season_name) {
          subtitleTemplate = subtitleTemplate.replace('{season}', `${season_name} •`);
        } else {
          subtitleTemplate = subtitleTemplate.replace('{season}', '');
        }

        //replace {single_date} placeholder
        let single_date = asset?.single_date;
        if (single_date) {
          subtitleTemplate = subtitleTemplate.replace('{single_date}', `${single_date} •`);
        } else {
          subtitleTemplate = subtitleTemplate.replace('{single_date}', '');
        }

        //replace {timerange} placeholder
        let timerange_name = asset?.time_range?.name;
        if (timerange_name) {
          if (timerange_name.toLowerCase() === 'custom') {
            subtitleTemplate = subtitleTemplate.replace('{timerange}', `${asset.time_range.start_time} - ${asset.time_range.end_time} •`);
          } else {
            subtitleTemplate = subtitleTemplate.replace('{timerange}', `${timerange_name} •`);
          }
        } else {
          subtitleTemplate = subtitleTemplate.replace('{timerange}', '');
        }

        //clothing
        let clothing = asset?.qualifiers?.clothing;
        if (clothing) {
          subtitleTemplate = subtitleTemplate.replace('{clothing}', `${clothing} •`);
        } else {
          subtitleTemplate = subtitleTemplate.replace('{clothing}', '');
        }

        //activity level
        let activity = asset?.qualifiers?.activity;
        if (clothing) {
          subtitleTemplate = subtitleTemplate.replace('{activity}', `${activity} •`);
        } else {
          subtitleTemplate = subtitleTemplate.replace('{activity}', '');
        }

        if (subtitleTemplate.endsWith(' •')) {
          subtitleTemplate = subtitleTemplate.slice(0, -2); //removes the last bullet symbol
        }
        subtitle = subtitleTemplate;
      }

      return subtitle;
    },
    findMatchingWindroses(asset) {

      let unique_by_name = _.uniqBy(this.allWindroses, 'filename');
      //filter to the right season.
      let seasons_matching_windroses = unique_by_name.filter(windrose => (windrose.season_filter === asset?.simulationResult?.season_filter));

      if (seasons_matching_windroses.length == 1){
        return seasons_matching_windroses;
      }

      // filter to the right timeframe
      let season_and_time_matching_windroses = seasons_matching_windroses.filter(windrose => (windrose.time_filter === asset?.simulationResult?.time_filter));

      if (season_and_time_matching_windroses.length == 1) {
        // exact match for season and time
        return season_and_time_matching_windroses;
      }

      //no exact match for season and time found.  Is there a Daily or AllDay one that matches the season?
      if (seasons_matching_windroses.length > 0) {
        let daily_season_windroses = seasons_matching_windroses.filter(windrose => windrose.time_filter.toLowerCase() === 'daily' || windrose.time_filter.toLowerCase() === 'allday');
        if (daily_season_windroses.length > 0) {
          return daily_season_windroses;
        }
      }

      //no matching season, if this is a PLW or directional result, is there an annual windrose available?
      if ((asset?.simulationResult?.analysis_type === 'Pedestrian Level Wind' || asset?.simulationResult?.analysis_type === 'Pedestrian Wind Comfort' || asset?.simulationResult?.analysis_type === 'Directional Wind') ) {
        let annual_windroses = this.allWindroses.filter(windrose => windrose.season_filter.toLowerCase() === 'annual');
        return annual_windroses;
      }

      return [];
    },
    reset() {
      this.colorScales = null;
      this.windroses = [];
    },
    async toggleAsset(asset, toggleOn) {
      if (!this.loadedAssets.some(loadedAsset => loadedAsset.simulationResult.id == asset.simulationResult.id)) {
        this.loadedAssets.push(asset);  //need to keep track of what's been loaded so that they can be turned off if the selectedSimulation changes
      } else {
        let existingAsset = this.loadedAssets.find(a => a.simulationResult.id == asset.simulationResult.id);
        asset = existingAsset;
      }
      // don't load an asset that's already loading
      if (toggleOn && !asset.isLoading) { // load asset
        asset.isLoading = true;
        //load the asset itself
        if (!asset.item) {
          try {
            let obj3d = await this.loadSimulationResult(asset.simulationResult);
            if (!obj3d) {
              asset.isLoading = false;
              console.error('3D file could not be loaded:',asset.filename);
              return;
            }
            if(this.handleRenderEvent(asset.simulationResult)){
              const renderedAssetInformation = await this.viewerRenderAsset(asset.simulationResult, obj3d);
              asset.item = renderedAssetInformation.item;  //record it's GUID from the visulizer so we can toggle it on/off
              asset.isLoading = false;
              // Verify after dispatching a render event if the rendered object is on screen again - to prevent artifacts from other scenarios
              if(!this.handleRenderEvent(asset.simulationResult)){
                this.viewer.setVisible({ id: asset?.item, visible: false });
              }
            }
          } catch(e) {
            asset.isLoading = false;
            this.showUnauthorizedAssetError();
          }
          [this.geometryFloor, this.geometryHeight] = this.viewer.getGeometryBoundaries();
          if(!this.clippingPlaneMode) this.setClippingPlaneHeight(this.geometryHeight);
        } else {
          //already loaded by the visualizer. Set it's visibility instead of loading as it's faster
          this.viewer.setVisible({ id: asset?.item, visible: true }); // don't await this async call - presentation planes dropbox will close before user has finished using it
        }
        asset.isLoading = false;
        // load vtp and save points and point data for selected horizontal layer for ML only
        // also only running this code when the env isn't 'test' because vtk.js can't be imported in the test env
        if(asset.simulationResult.layer_type === 'Horizontal' && process.env.NODE_ENV !== 'test') {
          if (asset?.simulationResult?.output_formats) {
            let vtpasset = asset.simulationResult.output_formats.find(file => file.format === 'vtp');
            if (vtpasset) {
              let vtpPath = vtpasset.path;
              // read and cache data file
              const reader = vtkXMLPolyDataReader.newInstance();
              reader.setUrl(vtpPath).then(() => {
                const polydata = reader.getOutputData(0);
                this.vtpPointData[asset.item] = polydata.getPointData().toJSON().arrays[0].data.values;
                this.vtpPoints[asset.item]  = polydata.getPoints().toJSON().values;
              });
            }
          }
        }
      } else {
        if (asset.item) {  //the layer panel sets everything to visible = false in a loop at times but if something was never loaded then asset.item (it's GUID) is null and there's nothing for the visulizer to hide
          this.viewer.setVisible({ id: asset.item, visible: false }); // don't await this async call - presentation planes dropbox will close before user has finished using it
          if(this.isSimulationInbound) {
            this.viewer.remove({ id: asset.item });
            asset.item = null;
          }
        }
      }
    },
    studyBuildingRequiresTransparency(layerSet){
      return layerSet.layerSetAssets.some(asset => 
        asset.isVisible && asset.simulationResult?.analysis_type === 'Cladding'
      );
    },
    handleRenderEvent(asset) {
      // The purpose of this function is to handle rendering events for a given asset. Based on this, it dispatches a render event to the 3D visualizer.
      const { currentInsight, resultLayersList, geometryLayersList, insightLayersList, isInsightsMode, isViewerMode, addedLayerSets, sceneViewCompositionMode } = this;
      const activeInsightLayers = currentInsight?.sceneview_set?.[0]?.active_layers || null;
      
      // Extract insight layers
      const insightLayers = !isViewerMode && activeInsightLayers?.filter(layer => {
        const [, fileExtension] = layer.asset_file?.split('?')[0].match(/\.([^.]+)$/) || [];
        return ['gltf', 'vtp', 'stl', 'glb'].includes(fileExtension);
      }).map(layer => layer.asset_file?.split('?')[0]) || [];

      const addedLayers = sceneViewCompositionMode && addedLayerSets.flatMap(layerSet => layerSet.layerSetAssets.map(layerSetAsset => {
        const assetFileNoParams = layerSetAsset.simulationResult.asset_file.split('?')[0];
        if (layerSetAsset.isVisible) {
          return assetFileNoParams;
        } else return null;
      })) || [];

      // Extract result layers
      const resultsUrls = new Set([...(!isInsightsMode && resultLayersList ? resultLayersList.flatMap(item => item.layerSetAssets.map(asset => {
        const assetFile = asset.simulationResult.asset_file;
        if (assetFile) {
          return assetFile;
        }
        console.error('The following asset does not have a valid URL:', asset.filename);
        console.warn('Please try uploading the file again.');
        return null;
      }).filter(Boolean)) : [])]);

      // Extract geometry layers
      const geometryUrls = new Set([...((!isInsightsMode || sceneViewCompositionMode) && geometryLayersList ? geometryLayersList.flatMap(item => item.layerSetAssets.map(asset => {
        const assetFile = asset.simulationResult.asset_file;
        if (assetFile) {
          return assetFile;
        }
        console.error('The following asset does not have a valid URL:', asset.filename);
        console.warn('Please try uploading the file again.');
        return null;
      }).filter(Boolean)) : [])]);

      // Extract insight layers from insightsTitle.vue
      const insightLayersSet = new Set([...(!isViewerMode && insightLayersList ? insightLayersList.flatMap(item => item.layerToToggle.layerSetAssets?.flatMap(asset => {
        const assetFile = asset?.simulationResult?.asset_file;
        if (assetFile) {
          return assetFile;
        }
        console.error('The following asset does not have a valid URL:', asset.simulationResult.filename);
        console.warn('Please try uploading the file again.');
        return null;
      }).filter(Boolean) || []) : [])]);

      // Check if the asset URL is present in any of the sets or arrays
      const hasMatch = addedLayers.includes(asset.asset_file?.split('?')[0]) || resultsUrls.has(asset.asset_file) || insightLayersSet.has(asset.asset_file) || geometryUrls.has(asset.asset_file) || insightLayers.includes(asset.asset_file?.split('?')[0]);

      if (!hasMatch) {
        console.info('Asset not present - skipping loading for:', asset.filename);
        return false;
      }
      console.info('Loading asset', asset.filename);
      return true;
    },
    async changeAssetTheme(asset, theme) {
      if (this.loadedAssets.some(loadedAsset => loadedAsset.simulationResult.id == asset.simulationResult.id)) {
        let existingAsset = this.loadedAssets.find(a => a.simulationResult.id == asset.simulationResult.id);
        asset = existingAsset;
      }
      await this.viewer.changeAssetTheme({ asset, theme });
    },
    async loadLayerSet(layerSet) {
      if (layerSet) {
        this.setLayerIsLoading(true);  //notify the store that a layer is being loaded
        if (!layerSet.IsGeometryLayer) {
          this.vtpPoints = {};
          this.vtpPointData = {};
        }
        for (const asset of layerSet.layerSetAssets) {
          await this.toggleAsset(asset, asset.isVisible); //only show the asset if it's presentation plane is marked as visible, otherwise turn it off it's been loaded(item != null)
        }
        if (layerSet.layerSetAssets.length > 0) {
          await this.updateColorLegendAndWindrose(layerSet.layerSetAssets[0]);//load any associated windrose and color scales
        }

        this.setLayerIsLoading(false);  //notify the store that the layer is done loading
      }
    },
    /* tells the visualizer to hide a visisble layer.  */
    async hideLayer(layer) {
      // Using forEach with async operations is problematic. The function will return before all assets are hidden. Changed to Promise.all()
      await Promise.all(layer.layerSetAssets.map(async asset => {
        //toggle off windrose & color scale
        if (asset?.simulationResult?.layer_type.toLowerCase() === 'horizontal') {
          this.horizontalColorScale = null;
        } else if (asset?.simulationResult?.layer_type.toLowerCase() === 'volumetric') {
          this.volumetricColorScale = null;
        }
        this.windroses = [];
        return this.toggleAsset(asset, false);
      }));
    },
    layerToggleComplete() {
      this.$store.dispatch('project/viewer/toggleLayers', null);
    },
    async setInsightCameraPosition() {
      if (this.insightCameraPosition) {
        let duration = 2500;
        const oldCamera = this.viewer.getCamera();
        const oldCameraPos = oldCamera.position;
        const newCameraPos = this.insightCameraPosition.position;
        const oldCameraType = oldCamera.object.type;
        const newCameraType = this.insightCameraPosition.object.type;
        if (oldCameraType !== newCameraType) {
          this.setCameraView(newCameraType);
          duration = 0;
        } else if (newCameraPos.x === oldCameraPos.x && newCameraPos.y === oldCameraPos.y && newCameraPos.z === oldCameraPos.z) {
          duration = 0;
        } else if(this.exportingPDF) {
          duration = 0;
        }
        await this.viewer.setCamera({
          position: this.insightCameraPosition.position,
          target: this.insightCameraPosition.target,
          zoom: this.insightCameraPosition.object.zoom,
          duration: duration
        });
      }
    },
    drawPinsForInsight() {
      let insight = this.currentInsights[this.currentInsightIndex];
      insight?.pins.forEach(pin => {
        if(!pin.item) {
          pin.item = this.viewer.drawPinAtPoint(pin.x_coord, pin.y_coord, pin.z_coord, pin.tag, pin.colour, pin.icon);
        } else {
          this.viewer.setVisible({ id: pin.item, visible: true });
        }
      });
      this.viewer.renderPins();
    },
    erasePinsForInsight(index) {
      if (this.currentInsights.length > 0) {
        let insight = this.currentInsights[index];
        if (insight) {
          insight.pins.forEach(pin => {
            this.viewer.setVisible({ id: pin.item, visible: false });
          });
        }
      }
    },
    setThemeIfCurrentInsightIsSceneView() {
      if(this.currentInsightIsSceneView) {
        let sceneView = this.currentInsight.sceneview_set[0];
        if (sceneView)
          this.selectedThemeIndex = this.themeLabels.indexOf(sceneView.theme);
      }
    },
    async setSelectedThemeIndex(index) {
      this.selectedThemeIndex = index;
      if(this.isPreviewModeOpen) {
        this.chosenInboundThemeIndex = index;
      } else {
        this.chosenResultThemeIndex = index;
      }
      this.$store.dispatch('project/setSelectedThemeIndex', index);
      if (this.studyBuildingTransparent) {
        // If the study is already transparent and we change themes, we need to set the building back to it's original
        // opacity and then set it to transparent again - it's a hack, but works :D
        await this.viewer.toggleStudyBuildingTransparent(false);
        await this.viewer.toggleStudyBuildingTransparent(true);
      }
    },
    cancelScreenshots(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.screenshotsModalText = 'Cancelling screenshots...';
      this.cancellingScreenshots = true;
      this.takeMultipleScreenshots(false);
    },
    enterSceneCompositionMode() {
      this.$store.dispatch('project/setSceneViewCompositionMode', true);
    },
    editSimulation() {
      this.configurationIdToEdit = Number(this.$route.params.configuration);
      this.scenarioNameToEdit = this.simulationLabel;
      this.simulationIdToEdit = this.simulation.id;
      this.openEditModal = !this.openEditModal;
    },
    ...mapActions({
      resizeViewerCanvas: 'project/viewer/setWindowResizeRequired',
      storeImportResults: 'project/importResults',
      setScenarioSubmissionInProgress: 'project/setScenarioSubmissionInProgress',
      setAnnotationTypeToCreate: 'project/setAnnotationTypeToCreate',
      setShowCreatingInsightPDFLoadingScreen: 'project/setShowCreatingInsightPDFLoadingScreen',
      setDataProbeStatus: 'project/viewer/setDataProbeStatus',
      setMLStatus: 'project/viewer/setMLStatus',
      setmlVersionInformation: 'project/simulationAsset/setMlVersionInformation'
    })
  },
  watch: {
    clearAxesHelperIndex(newVal, oldVal){
      if(newVal != oldVal && !this.refPickerInitiated){
        this.showRefPicker = false;
        this.viewer.clearAxesHelper();
      }
    }, 
    showRefPicker(newVal){
      if(newVal)
        this.refPickerIndex += 1;
      if(newVal && this.activeCameraView != 'orthographic')
        this.setCameraView('orthographic');
      if(!newVal && this.activeCameraView == 'orthographic')
        this.setCameraView('perspective');
      this.refPickerInitiated = true;
    },
    async creatingInsightPDF(newValue) {
      if (newValue === true) {
        await this.captureAllScreenshots();
        this.$store.dispatch('project/setCreatingInsightPDF', false);
      }
    },
    dataProbeStatus(newValue) {
      if(!newValue)
        this.clearDataProbes();
    },
    resultLayersPresent: {
      handler(newVal) {
        this.resultLayersList = newVal;
      },
      immediate: true, // Trigger the handler immediately with the current value
    },
    geometryLayersPresent: {
      handler(newVal) {
        this.geometryLayersList = newVal;
      },
      immediate: true, // Trigger the handler immediately with the current value
    },
    insightLayersPresent: {
      handler(newVal) {
        this.insightLayersList = newVal;
      },
      immediate: true, // Trigger the handler immediately with the current value
    },
    createScenarioStep(newValue) {
      if (newValue > 1 ) {
        this.parametersPanelCreated = true;
      }
    },
    drawingMode(newValue) {
      if(newValue && this.isInsightsMode && (!this.drawingToolHasntBeenToggledOff || this.cameraPositionHasBeenUpdated)) {
        this.setInsightCameraPosition();
      } else if (!newValue) {
        this.drawingToolHasntBeenToggledOff = false;
      }
    },
    hasScene(newValue) {
      if (newValue) {
        this.resizeViewerCanvas(true);
      }
    },
    showProbeMode(newValue) {
      //if probe mode is no longer available (selected layer has no vtk) then tell the viewerToolbar, so it can toggle the button, and remove any probes on the screen
      if(newValue == false) this.clearDataProbes();
    },
    async wireframeOn() {
      for (const [key, value] of Object.entries(this.wireframeOn)) {
        for (const layer of this.geometryLayerSets) {
          if (layer.identifier == key) {
            const group_name = this.generateGroupFromAsset(layer.layerSetAssets[0].simulationResult);
            await this.viewer.showWireframe(group_name, value);
          }
        }
      }
    },
    async windowResizeRequired(newValue) {
      await this.viewer.resizeWindow(this.$refs.viewer);
      if (newValue) {
        if (this.isInsightsMode) {
          this.setInsightCameraPosition();
          this.setThemeIfCurrentInsightIsSceneView();
          this.drawPinsForInsight();
        }
        this.resizeViewerCanvas(false);
      }
    },
    sceneViewCompositionMode() {
      this.resizeViewerCanvas(true);
    },
    async $route(to, from) {
      // when switching from split view to single view or from single view to split view, resize viewer
      if((!!to.query.secondary && !from.query.secondary) || (!to.query.secondary && !!from.query.secondary)) {
        this.selectedThemeIndex = 0;
        await this.viewer.resizeWindow(this.$refs.viewer);
      }
    },
    async viewerMode(newValue, oldValue) {
      
      if (newValue === 'Insights' && oldValue !== 'Insights') {
        this.setInsightCameraPosition();
        this.setThemeIfCurrentInsightIsSceneView();
        this.drawPinsForInsight();
        this.windroseModalVisible = false;  //turn off windrose and color legend
        this.colorScaleMenuVisible = false;
        this.cameraPositionHasBeenUpdated = false;
      } else if (newValue !== 'Insights' && oldValue === 'Insights') {
        this.erasePinsForInsight(this.currentInsightIndex);
      } else {
        this.colorScaleMenuVisible = this.currentInsight?.sceneview_set[0]?.legend_on;
        this.windroseModalVisible = this.currentInsight?.sceneview_set[0]?.windrose_on;
      }


      if (newValue == 'Insights' && this.currentInsight?.sceneview_set.length > 0) {
        this.colorScaleMenuVisible = this.currentInsight?.sceneview_set[0]?.legend_on;
        this.windroseModalVisible = this.currentInsight?.sceneview_set[0]?.windrose_on;
      } else {
        this.colorScaleMenuVisible = false;
        this.windroseModalVisible = false;
      }
      
      await this.$nextTick();
      this.viewer.reattach_to_DOM(this.$refs.viewer);
      this.setClickEvents();
    },
    selectedSimulationId(newValue, oldValue) {
      // when the selected simulation changes, reset the viewer
      if (newValue) {
        // reset the sim-settings menu each time a simulation is toggled
        this.$bvToast.hide(`${this.rank}-sim-settings-menu`);
        this.showSettingDetail = false;
        this.simSettingsInfo = '';
        this.menuIconVisible = true;

        //reset the colorscale and windroses
        this.reset();
      }
      if (newValue != oldValue) {
        this.refPickerInitiated = false;
        this.showRefPicker = false;
        this.viewer.clearAxesHelper();
      }
    },
    geometryChangeTheme(newValue) {
      if(newValue.asset) this.changeAssetTheme(newValue.asset, newValue.theme);
    },
    async layersToToggle(newValue) {
      if (newValue && newValue.length > 0) {
        if (newValue.some(layer => layer.layerToToggle.IsGeometryLayer == false)) {
          this.clearDataProbes();
        }
        for (let layer of newValue) {
          if (layer.toggleOn) {
            this.loadLayerSet(layer.layerToToggle);
          } else {
            this.hideLayer(layer.layerToToggle); // same as above
          }
          // if(this.studyBuildingRequiresTransparency(layer.layerToToggle)){
          //   if (layer.toggleOn === false && this.studyBuildingTransparent){
          //     this.studyBuildingTransparent = false;
          //     await this.viewer.toggleStudyBuildingTransparent(false);
          //   } else if (layer.toggleOn === true && !this.studyBuildingTransparent){
          //     this.studyBuildingTransparent = true;
          //     await this.viewer.toggleStudyBuildingTransparent(true);
          //   }
          // }
        }

        //this watch is triggered separately for geometry vs result layers.  Toggling geometry layers shouldn't affect whether or not we
        //need study building transparency to avoid the mottled building look when result layers overlap with geometry so the below code 
        //that decides to turn study building transparency on/off only needs to execute when result layers are toggled on/off
        if (!newValue[0].layerToToggle.IsGeometryLayer) {  

          //check to see if any layers being turned on need study-transparency enabled.
          let study_building_should_be_tranparent = false;
          for (let layer of newValue) {
            if (layer.toggleOn) {
              const layer_needs_transparency = this.studyBuildingRequiresTransparency(layer.layerToToggle);
              if (layer_needs_transparency) {
                study_building_should_be_tranparent = true;
              }
            }
          }
          
          if (!this.studyBuildingTransparent && study_building_should_be_tranparent) {
            //if study-transparency is required and not enabled, enable it
            await this.viewer.toggleStudyBuildingTransparent(true);
            this.studyBuildingTransparent = true;
          } else if (this.studyBuildingTransparent && !study_building_should_be_tranparent) {
            //if study-transparency is NOT required and IS enabled, disable it
            await this.viewer.toggleStudyBuildingTransparent(false);
            this.studyBuildingTransparent = false;
          }
        }


        this.layerToggleComplete();
      }
    },
    cameraPosition() {
      if(this.onSavedCamera) this.onSavedCamera = false;
    },
    async currentInsightIndex(_, oldValue) {
      if(this.isInsightsMode) {
        this.colorScaleMenuVisible = this.currentInsight?.sceneview_set[0]?.legend_on;
        this.windroseModalVisible = this.currentInsight?.sceneview_set[0]?.windrose_on;
        this.setInsightCameraPosition(); // When new insights are rendered we show the camera position associated with that insight
        this.setThemeIfCurrentInsightIsSceneView();
        this.erasePinsForInsight(oldValue);
        this.drawPinsForInsight();
        await this.viewer.resizeWindow(this.$refs.viewer);
        this.drawingToolHasntBeenToggledOff = true;
        this.cameraPositionHasBeenUpdated = false;
      }
    },
    async currentInsight() {  //this seems redundant with the currentInsightIndex watcher above except that doesn't seem to fire if you refresh the page and this does...
      if(this.isInsightsMode) {  
        this.colorScaleMenuVisible = this.currentInsight?.sceneview_set[0]?.legend_on;
        this.windroseModalVisible = this.currentInsight?.sceneview_set[0]?.windrose_on;
        this.setInsightCameraPosition(); // When new insights are rendered we show the camera position associated with that insight
        this.setThemeIfCurrentInsightIsSceneView();
        this.drawPinsForInsight();
        await this.viewer.resizeWindow(this.$refs.viewer);
      }
    },
    selectedThemeIndex(newValue) {
      this.$emit('selectTheme', newValue);
    },
    simulationHasResultAssets(newValue) {
      if(newValue && !this.chosenResultThemeIndex) {
        this.selectedThemeIndex = this.themeLabels.indexOf('default');
      } else if (!newValue && !this.chosenResultThemeIndex) {
        this.selectedThemeIndex = this.themeLabels.indexOf('geometry');
      }
    },
    isPreviewModeOpen(newValue) {
      if (newValue) {
        if(this.chosenInboundThemeIndex) {
          this.selectedThemeIndex = this.chosenInboundThemeIndex;
        } else {
          this.selectedThemeIndex = this.themeLabels.indexOf('geometry');
        }
      } else {
        if(this.chosenResultThemeIndex) {
          this.selectedThemeIndex = this.chosenResultThemeIndex;
        } else if(this.simulationHasResultAssets) {
          this.selectedThemeIndex = this.themeLabels.indexOf('default');
        } else {
          this.selectedThemeIndex = this.themeLabels.indexOf('geometry');
        }
      }
    },
    async selectedColorTheme(newValue) {
      if (this.viewer)
        await Promise.all(newValue?.map(theme => this.viewer.setColorScheme(theme)));
    },
    async layerPanelActive() {
      // resize viewer when layer panel opens/closes
      await this.viewer.resizeWindow(this.$refs.viewer);
    },
    //TO DO:  I don't think this watch and the associated method belong in Viewer.vue.  They react to the store, set some stuff up, and call the store again.
    //This could really go anywhere but since it's not related to drawing things in the viewer this doesn't feel like the right spot.  Perhaps in appContainer.vue?
    async geometryAssets(newArray) {
      await this.submittedFormValues(newArray);
    },
    abortLoadFlag(newValue) {
      if(newValue) {
        this.abortLoad();
        this.$store.dispatch('project/viewer/setAbortLoadFlag', false);
      }
    },
    loadingMultipleScreenshots(newValue) {
      if (newValue) {
        this.cancellingScreenshots = false;
        this.screenshotsModalText = 'Please wait, screenshots in progress...';
      }
    },
    removeAllLayers(newValue) {
      if (newValue) {
        this.erasePinsForInsight(this.currentInsightIndex);
        this.$emit('removingAllLayers', false);
      }
    },
    async simulation(newValue) {
      if(newValue) {
        this.newResultsAvailableText = '';
        this.moreResultsAvailable = false;

        let args = { 
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          simulation: newValue
        };
        await this.setmlVersionInformation(args);
        
      }
    }
  },
  beforeDestroy() {
    EventBus.$off('RESULTS_DELIVERED', this.onResultsDelivered);
    EventBus.$off('ML_STATUS_UPDATE', this.onMLStatusUpdate);
    window.removeEventListener('resize', this.setCanvasSizeFromAspect);
    this.abortLoad();
    this.loadedAssets.forEach(asset => this.toggleAsset(asset, false));
    this.easterEgg.unload();
  },
};
</script>

<style>
  .sprite-label{
    margin-top: 0.85rem;
    color: black;
    background:white;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
  /* Unscoped style tags to affect toast css */
  [id$="sim-settings-menu"] .toast-body{
    background-color: rgba(12, 31, 41, 0.8) !important;
    margin-top: 0;
  }

  [id$="sim-settings-menu"].toast{
    background-color: transparent;
  }

canvas {
  margin-top: 0px;
}
</style>

<style scoped>
.compose-scene-btn {
  left: calc(50% - 74.6565px);
  bottom: calc(50% - 18.8px);
  position: absolute;
}

button > * {
  margin: 0;
}

#toast-container{
  display: flex;
}

#sim-settings-ul-container{
  width: 100%;
}

#toast-container #sim-settings-ul-container ul{
  padding: 0.563rem 0;
  margin-bottom: 0;
}

#toast-container #sim-settings-ul-container ul.no-conf-index{
  padding: 0rem 0 2.5rem;
}

#toast-container #sim-settings-ul-container ul li{
  list-style-type: none;
}
#toast-container #sim-settings-ul-container  ul li.selected{
  color: rgba(12, 31, 41, 0.8);
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 0.125rem;
}

#toast-container #sim-settings-ul-container  ul li:hover{
  cursor: pointer;
}

#toast-container #sim-settings-ul-container  ul li span{
  margin-left: 0.313rem;
}

[id$="sim-settings-menu__toast_outer"] {
  color: #fff;
  margin: 0.031rem ;
}

[id$="sim-settings-menu__toast_outer"] div.toast-body{
  margin-top: -1.875rem;
  padding: 0.75em 0rem;
  color: #fff;
}

#sim-settings-header{
  font-size: 1rem;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
  color: #364046;
  margin-right: 0.938rem;
}

#sim-details.narrow{
  width: 12.5rem;
}

#sim-details.wide{
  width: 16.25rem;
}

#sim-details{
    width: 21.188rem;
    padding: 0 0.625rem;
    border: 0.063rem solid rgba(0, 0, 0, 0.1);
    border-radius: 0 0.25rem 0.25rem 0;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
    background-color: rgba(12, 31, 41, 0.8);
    color: white;
    margin-top: 2.375rem;
    overflow-y: scroll;
}

[id$="_viewer_canvas"] {
  margin: 0;
  background-color: var(--grey-200);
  overflow: hidden;
  width: 100%;
  height: calc(100% - 62px);
  position: absolute;
  top: 3.875rem;
  z-index: 0;
}

#geometry-validation-container, #sim-parameters-container, #import-results-container {
  margin: 0;
  background-color: var(--grey-200);
  overflow: hidden;
  width: 100%;
  height: calc(100% - 7.063rem);
  z-index: 1;
}

.no-assets {
  margin: 0;
  background-color: var(--grey-200);
  overflow: hidden;
  width: 100%;
  height: calc(100% - 7.063rem);;
  position: relative;
}

.no-assets p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
}

#screenshot_controls {
  position: fixed;
  top: 6.2rem;
  left: 50%;
  z-index: 3;
}

#probeTypeDisplay {
  position:absolute;
  z-index: 2;
  left:42%;
}

#sceneCompositionModeDisplay {
  position:absolute;
  z-index: 2;
  left:30%;
}

#annotationCompositionDisplay {
  position:absolute;
  z-index: 2;
  left: 38%;
}

#processingInProgressDisplay {
  position:absolute;
  z-index: 2;
  left: 4em;
}

#compass_container {
  position: absolute;
  margin: 0;
  top: 4.5em;
  left: 0.95em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

[id$="_compass"] {
  height: 2.5em;
  width: 2.5em;
}

.canvas-wrapper {
  position: relative;
  margin: 0;
  flex-grow: 1;
  width: 31.25rem;
  height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
}

.reset-button {
  position: absolute;
  top: 6em;
  left: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  background-color: #fff;
  color: var(--grey-900);
  border-color: var(--grey-400);
  width: 1.5em;
  height: 1.5em;
  padding: 0.5em;
  margin: 0;
}

.reset-button svg {
  width: 1em;
  height: 1em;
}

.reset-button svg path {
  fill: var(--grey-700);
}

.reset-button:focus,
.reset-button:active {
  border-color: var(--primary-cerulean);
}

.reset-button:hover svg path,
.reset-button:focus svg path,
.reset-button:active svg path {
  fill: var(--grey-900);
}

/* Keyboard menu styling */
.toolbar-container {
  padding: 0.75rem;
  background-color: var(--grey-300);
  width: calc(100% - 1.5rem);
  opacity: 1;
  position: relative;
  height: 2.375rem;
}

.scenario-submissions-container {
  background-color: #063970;
  padding: 0.8em;
  margin: 0;
  position: relative;
  z-index: 2;
}

.controls-info-container.nondisplaced{
  bottom: 1em;
}

.controls-info-container.displaced{
  bottom: 3.25em;
}

.controls-info-container {
  position: absolute;
  left: 1em;
  z-index: 3;
  display: flex;
}

.controls-info-button {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: var(--grey-900);
  border-color: var(--grey-400);
  border-radius: 50%;
  padding: 0;
  margin-bottom: 0;
}

.controls-info-button svg circle {
  fill: #fff;
}

.controls-info-button svg path {
  fill: none;
  stroke: #737e87;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.094rem;
}

.controls-info-button:hover svg path,
.controls-info-button:focus svg path,
.controls-info-button:active svg path {
  stroke: var(--grey-900);
}

.keyboard-info-menu {
  position: relative;
  left: 0;
  bottom: 0em;
  min-width: 20em;
  margin-top: 0;
}

.controls-info-container.hidden .keyboard-info-menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}

.controls-info-container.visible .keyboard-info-menu {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

/* Colour Scale Menu Styling */
#colour-scale-container {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 2;
}

.colour-scale-button {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: var(--grey-900);
  border-color: var(--grey-400);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  max-height: 36px;
}

.colour-scale-button svg circle {
  fill: #fff;
}

.colour-scale-button svg path {
  fill: none;
  stroke: #737e87;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.094rem;
}

.colour-scale-button:hover svg path,
.colour-scale-button:focus svg path,
.colour-scale-button:active svg path {
  stroke: var(--grey-900);
}

.colour-scale-menu {
  z-index: 2;
  min-width: 20em;
  margin-right: 1em;
}

.a {
  fill: #fff;
  stroke: #e2e6e9;
}

.b, .c, .e {
  fill: none;
}

.b, .c {
  stroke: #737e87;
  stroke-linecap: round;
  stroke-width:0.094rem;
}

.b {
  stroke-linejoin: round;
}

.d {
  stroke:none;
}

.st0{
  opacity:0.5;
  enable-background: new;
}

.st1{
  fill:#FFFFFF;
}

.st2{
  opacity:0.5;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(144,150,151,.5);
  border-radius: 0.313rem;
  padding: 3.0rem 0.75rem;
}

.no-color-scale {
  min-width: 18rem;
  max-width: 24rem;
}

/* Save camera position styling */
.save-camera-container {
  position: absolute;
  top: 2.0em;
}

.button-container > * {
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

/* Windrose container */
.windrose-container {
  position: absolute;
  right: 1em;
  top: 3em;
  z-index: 2;
}

.windrose-container.hidden .windrose-button {
  display: flex;
}

.windrose-container.visible .windrose-button {
  display: none;
}

.windrose-button {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: var(--grey-900);
  border-color: var(--grey-400);
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.windrose-button svg circle {
  fill: #fff;
}

.windrose-button svg path {
  fill: none;
  stroke: #737e87;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.094rem;
}

.windrose:hover svg path,
.windrose:focus svg path,
.windrose:active svg path {
  stroke: var(--grey-900);
}

.windrose-menu {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 20em;
}

.windrose-container.hidden .windrose-menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}

.windrose-container.visible .windrose-menu {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

#compass {
  cursor: pointer;
}

.settings-button-container > * {
  all: revert;
  position: relative;
  padding-top: 0.5em;
  display: grid;
  grid-template-columns: 12.5rem;
  grid-template-rows: 1.563rem;
  background-color:white;
  color: var(--grey-600);
  font-weight: 700;
  border: 0.125rem solid var(--grey-500);
  border-radius: 0.313rem;
  cursor:pointer;
}

.camera-position-button {
  all: unset;
  margin: 0;
  font-size: 0.700rem;
  color: var(--grey-900);
  font-weight: 700;
  text-decoration: underline;
  cursor:pointer;
}

.loading-spinner {
  height: 1.25em;
  width: 1.25em;
  padding: 0.5em 1.5em!important;
}

.menu {
  all: unset;
  margin: 0;
  position: relative;
  width: 1.1em;
  height: 1.1em;
  background-color: var(--grey-200);
  color: var(--grey-600);
  text-align: center;
  line-height: 1.1em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50%;
}

.menu .hover-menu {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  width: 3.125rem;
  display: grid;
  grid-template-columns: 60%;
  align-items: baseline;
  background: var(--navy) 0% 0% no-repeat padding-box;
  border: 0.063rem solid var(--grey-300);
  background: #00395E 0% 0% no-repeat padding-box;
  border: 0.063rem solid #E2E6E9;
  border-radius: 0.313rem;
  opacity: 1;
}

.menu .hover-menu.active {
  visibility: visible;
}

.hover-menu button {
  background: none;
  border: none;
  color: var(--white);
  text-align: left;
  font: normal normal normal 0.625rem/0.75rem Inter;
  letter-spacing: 0rem;
  color: #FFFFFF;
  opacity: 1;
}

/* Theme selection styling */
.theme-selection-container {
  position: absolute;
  top: 1em;
  left: 4em;
  margin: 0;
}

.dropdown {
  max-width: 2rem;
}

/* RWDI Reviewed */

.reviewed-by-rwdi-container {
  position: absolute;
  margin: 0;
  bottom: 2.25em;
  left: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.reviewed {
  color: var(--grey-900);
  background-color: #fff;
  border: none;
  position: fixed;
  border-radius: 20rem;
  font-size: 0.75em;
  padding: 0.25em 0.3em 0.25em 0.75em;
  box-shadow: rgba(99, 99, 99, 0.2) 0rem 0.125rem 0.5rem 0rem;
}

.reviewed:hover, reviewed:active {
  color: var(--grey-900);
  background-color: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.313rem 0.75rem;
}

.reviewed:before {
  content: '';
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDgwIDgwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA4MCA4MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6I0ZGRkZGRjt9DQoJLnN0MXtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiMwMTczQjk7fQ0KPC9zdHlsZT4NCjxyZWN0IHk9Ii0wLjEiIGNsYXNzPSJzdDAiIHdpZHRoPSI4MCIgaGVpZ2h0PSI4MC4xIi8+DQo8ZyBpZD0iQ2xpcC0yIj4NCjwvZz4NCjxnPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDEiIHBvaW50cz0iNjUuOCwxNC4xIDU2LjksMzkuOCA2NS41LDM5LjggNjUuNSw4MCA3OS45LDgwIDc5LjksMzkuOCA3NC43LDM5LjggCSIvPg0KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik00MC4xLDIyLjVWMGgtOS41YzAuNCwxLjYsMC45LDMuMywwLjksNS4ydjAuMWMwLDgtNC4yLDEzLjItMTAuNiwxNS45bDEyLjYsMTguNUgxNy4xbC0xMC44LTE2SDB2MTYuMWg4LjkNCgkJQzI4LjIsMzkuNywzOCw1MC4yLDM3LjgsNjYuMXYwLjNjMC4xLDQuMi0xLjMsOS45LTMuOSwxMy41aDE3LjZWMzkuOGgtNUw0MC4xLDIyLjV6Ii8+DQoJPHBhdGggY2xhc3M9InN0MSIgZD0iTTguNyw1Mi44SDBWODBoOC42YzguOSwwLDE0LjctNC45LDE0LjctMTMuNXYtMC4yQzIzLjMsNTcuOCwxNy42LDUyLjgsOC43LDUyLjh6Ii8+DQoJPHBhdGggY2xhc3M9InN0MSIgZD0iTTE3LjIsNi4zVjYuMmMwLTQuMS0zLTYuMi03LjctNi4ySDB2MTIuNWg5LjVDMTQuMywxMi41LDE3LjIsMTAuMSwxNy4yLDYuM3oiLz4NCgk8cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjQ1LjgsMCA1Mi4xLDIwLjggNTguOSwwIAkiLz4NCgk8cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjczLDAgODAsMjAuOCA4MCwwIAkiLz4NCjwvZz4NCjwvc3ZnPg0K");
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 0.5em -0.18em 0;
}

.reviewed:after {
  content: '';
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4NCiAgPGcgaWQ9Ikdyb3VwXzEiIGRhdGEtbmFtZT0iR3JvdXAgMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuNzUgLTAuOTk0KSI+DQogICAgPHBhdGggaWQ9IlBhdGhfMSIgZGF0YS1uYW1lPSJQYXRoIDEiIGQ9Ik0uNzUsMTguOTk0YTE4LDE4LDAsMSwwLDEuMzctNi44ODhBMTgsMTgsMCwwLDAsLjc1LDE4Ljk5NFoiIGZpbGw9IiMwMTczYjkiLz4NCiAgICA8cGF0aCBpZD0iUGF0aF8yIiBkYXRhLW5hbWU9IlBhdGggMiIgZD0iTTE1Ljc1LDE0Ljk5MWExLjUsMS41LDAsMSwwLDAsM2gxLjV2OWgtMS41YTEuNSwxLjUsMCwxLDAsMCwzaDZhMS41LDEuNSwwLDAsMCwwLTNoLTEuNXYtMTAuNWExLjUsMS41LDAsMCwwLTEuNS0xLjVaIiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4NCiAgICA8cGF0aCBpZD0iUGF0aF8zIiBkYXRhLW5hbWU9IlBhdGggMyIgZD0iTTE4Ljc1LDhhMiwyLDAsMSwwLDEuNDE0LjU4NkEyLDIsMCwwLDAsMTguNzUsOFoiIGZpbGw9IiNmZmYiLz4NCiAgPC9nPg0KPC9zdmc+DQo=");
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 0 -0.25em 0.5em;
}

.close-button {
  margin: 0;
  padding: 0;
  background-image: url('~@/assets/svg/close-button.svg');
  background-color: white;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  margin-right: 0;
  margin-left: auto;
  border: none;
}

.reviewed-by-rwdi-header-content {
  display: flex;
}
</style>

<style lang="scss">
/* Global styling for setting the size of the save camera position modal */
.save-camera-container .modal-container .content-container {
  max-width: 18.75rem;
}


select {
  text-transform: capitalize;
}

.loading-screenshots {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding-bottom: 2rem;
}
.probeCanSubmit button{
  background-color: #e2e6e9 !important;

}
.probeDisabledSubmit button{
  background-color: #005488 !important;
}
#hideProbePointSubmit{
  background-color: #e2e6e9;
}

// weird styling hack
.reference-origin-outer{
  margin-top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.coords input{
  background: #DCDAD1 !important;
  width: 5.375rem !important;
  margin-top: -1.875rem !important;
}

.structural-update div {
  margin-top: -1.875rem !important;
}


#ref-wrapper  .formulate-input-element.formulate-input-element--group.formulate-input-group .formulate-input-group-repeatable{
  display: flex;
}

#update-picker-btn{
  margin-right: 0.375rem; 
}

</style>